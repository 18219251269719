import React from 'react';
import {Grid, DialogContentText} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
const {DateField, TextField} = Formik;

const EditBatteryReceipt = () => {
  return (
    <>
      <DialogContentText>Shipment Details</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateField name='DateReceived' label='Date Received' required />
        </Grid>
        <Grid item xs={12}>
          <TextField name='SerialNoShipped' label='Serial Number Shipped' required disabled />
        </Grid>
        <Grid item xs={12}>
          <TextField name='SerialNoReceived' label='Serial Number Received' required />
        </Grid>
      </Grid>
    </>
  );
};

export default EditBatteryReceipt;
