import React, {Fragment, useState, useMemo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {object, string, date} from 'yup';
import moment from 'moment';
import {firestoreUtility} from 'utilities';
import {AsyncLoader} from 'components';
import sortBy from 'lodash.sortby';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {AutoComplete, DateField, FormButton, FormikForm, SelectField, SubmitButton, TextField} = Formik;

const ModalScheduleShipment = ({open, close, selectedReturn}) => {
  const {receivingSites} = useSelector(state => state.firestore);
  const [alert, setAlert] = useState({text: '', in: false, severity: 'warning'});
  const [stage, setStage] = useState('shipment');
  const handleClose = useCallback(() => {
    setStage('shipment');
    close();
  }, [close]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialValues: {
      Carrier: '',
      PickupDate: '',
      ShippingPaper: '',
      ShippingPO: '',
      ReceivingSiteRef: '',
      ReceivingSiteName: '',
      ReceivingSiteCountry: '',
      ReceivingSiteAddress1: '',
      ReceivingSiteAddress2: '',
      ReceivingSiteCity: '',
      ReceivingStateProvince: '',
      ReceivingSitePostalCode: '',
    },
    onSubmit: (values, actions) => {
      const gotoStage = (stage) => {
        setStage(stage);
        actions.setTouched({});
        actions.setSubmitting(false);
        setAlert({text: '', in: false, severity: 'warning'});
      };
      const submitData = async () => {
        try {
          const email = {
            collection: 'Emails',
            writeType: 'add',
            docFields: {
              to: selectedReturn.Contact.Email,
              bcc: ['daniel@kbirecycling.com'], // 'Julie.Batchelor@harley-davidson.com',
              template: {
                data: {
                  Carrier: values.Carrier,
                  ReturnNo: selectedReturn.ReturnNo,
                  ScheduleDate: moment(values.PickupDate).format('MM/DD/YYYY'),
                },
                name: 'PickupScheduled',
              },
            },
          };
          const updateDoc = {
            collection: 'BatteryReturns',
            writeType: 'update',
            docId: selectedReturn.BatteryReturnId,
            docFields: {
              'Date.Reminder': moment().add(10, 'days').toDate(),
              'ShipmentDetails.Carrier': values.Carrier,
              'ShipmentDetails.PickupDate': moment(values.PickupDate).toDate(),
              'ShipmentDetails.ShippingPaper': values.ShippingPaper,
              'ShipmentDetails.ShippingPO': values.ShippingPO,
              'Status': 'Shipment Scheduled',
              'ReceivingSite.Ref': values.ReceivingSiteRef,
              'ReceivingSite.Address1': values.ReceivingSiteAddress1,
              'ReceivingSite.Address2': values.ReceivingSiteAddress2,
              'ReceivingSite.City': values.ReceivingSiteCity,
              'ReceivingSite.Country': values.ReceivingSiteCountry,
              'ReceivingSite.Name': values.ReceivingSiteName,
              'ReceivingSite.PostalCode': values.ReceivingSitePostalCode,
              'ReceivingSite.StateProvince': values.ReceivingStateProvince,
            },
          };
          const promises = [firestoreUtility(updateDoc), firestoreUtility(email)];
          if (selectedReturn.PickupLocation.Country === 'Canada' && selectedReturn.Battery.Warranty && values.ReceivingSiteRef === 'hCROJ4bhr94hyXf6OyZX') {
            const customsEmail = {
              collection: 'Emails',
              writeType: 'add',
              docFields: {
                to: 'chrisvoss92@gmail.com', // customs@harley-davidson.com
                cc: 'hd-support@xevstrategies.com',
                bcc: ['daniel@kbirecycling.com', 'cvoss@teamnls.com'], // 'Julie.Batchelor@harley-davidson.com',
                template: {
                  data: {
                    DealerName: selectedReturn.DealerName || selectedReturn.AffiliateName,
                    DealerNo: selectedReturn.DealerNo || selectedReturn.AffiliateId,
                    Address1: values.ReceivingSiteAddress1,
                    Address2: values.ReceivingSiteAddress2,
                    City: values.ReceivingSiteCity,
                    Province: values.ReceivingStateProvince,
                    PostalCode: values.ReceivingSitePostalCode,
                    ShippingCarrier: values.Carrier,
                    ShippingDocNumber: values.ShippingPO,
                    PickupDate: moment(values.PickupDate).format('MM/DD/YYYY'),
                  },
                  name: 'CustomsNotification',
                },
              },
            };
            promises.push(firestoreUtility(customsEmail));
          }
          await Promise.all(promises);
          setTimeout(() => actions.resetForm(), 250);
          setStage('success');
          setAlert({...alert, in: false});
        }
        catch (error) {
          setAlert({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      };
      if (stage === 'shipment') gotoStage('receivingSite');
      else if (stage === 'receivingSite') submitData();
    },
    validationSchema: object().shape({
      Carrier: string().required(),
      PickupDate: date().label('Pickup Date').required().min(new Date(), 'Pickup Date must be set in the future'),
      ShippingPaper: string().label('Shipping Paper No.').required(),
      ShippingPO: string().label('Shipping PO No.'),
    }),
  }), [alert, selectedReturn, stage]);
  const receivingSiteProps = useMemo(() => ({
    label: 'Receiving Site',
    name: 'ReceivingSiteName',
    options: sortBy(receivingSites.list, 'Name') || [],
    optionKey: 'Name',
    required: true,
    onChange: ({field, form}) => {
      form.setFieldValue('ReceivingSiteRef', field.value.ReceivingSiteId);
      form.setFieldValue('ReceivingSiteCountry', field.value.Country);
      form.setFieldValue('ReceivingSiteAddress1', field.value.Address1);
      form.setFieldValue('ReceivingSiteAddress2', field.value.Address2);
      form.setFieldValue('ReceivingSiteCity', field.value.City);
      form.setFieldValue('ReceivingStateProvince', field.value.StateProvince);
      form.setFieldValue('ReceivingSitePostalCode', field.value.PostalCode);
    },
  }), [receivingSites.list]);

  if (receivingSites === null) return <ReceivingSiteLoader open={open} />;
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formikProps => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'}>Shipment Details</Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'shipment'}>
                <Typography>Add details about battery shipment.</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectField name='Carrier'>
                      <MenuItem value='FedEx'>FedEx</MenuItem>
                      <MenuItem value='Old Dominion'>Old Dominion</MenuItem>
                      <MenuItem value='Veolia'>Veolia</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateField name='PickupDate' label='Pickup Date' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='ShippingPaper' label='Shipping Paper No.' required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='ShippingPO' label='Shipping PO No.' />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'receivingSite'}>
                <Typography>Select receiving site and confirm details.</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <AutoComplete {...receivingSiteProps} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='ReceivingSiteCountry' label='Country' disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='ReceivingSiteAddress1' label='Address 1' disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='ReceivingSiteAddress2' label='Address 2' disabled />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='ReceivingSiteCity' label='City' disabled />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='ReceivingStateProvince' label={formikProps.values.ReceivingSiteCountry === 'Canada' ? 'Province' : 'State'} disabled />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='ReceivingSitePostalCode' label='Postal Code' disabled />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                      Shipment details were successfully added
                  </Typography>
                </div>
              </Collapse>
              <Alert text={alert.text} in={alert.in} severity={alert.severity} onClose={() => setAlert({...alert, in: false})} />
            </DialogContent>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='primary' variant='text'>Close</FormButton>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'}>
              <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                <FormButton onClick={handleClose} color='secondary' variant='text'>Cancel</FormButton>
                <SubmitButton variant='text'>{stage === 'receivingSite' ? 'Submit' : 'Next'}</SubmitButton>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

const ReceivingSiteLoader = ({open}) => {
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  return (
    <Dialog {...dialogProps}>
      <AsyncLoader />
    </Dialog>
  );
};
ReceivingSiteLoader.propTypes = {
  open: PropTypes.bool,
};

ModalScheduleShipment.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedReturn: PropTypes.object.isRequired,
};
export default ModalScheduleShipment;
