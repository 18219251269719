import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Button, Collapse, Grid, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {boolean, object, string} from 'yup';
import {firestoreUtility} from 'utilities';
import {countries, provinces, states} from 'constants.js';
import {postalRegex} from 'utilities';
import {Alert, Formik} from '@kbi/component-library';
const {AutoComplete, FormikForm, SelectField, TextField} = Formik;

const ReceivingSiteModal = ({close, row, type}) => {
  const [alert, setAlert] = useState({text: '', in: false, severity: 'warning'});
  const [stage, setStage] = useState('enterAffiliateSite');
  const handleClose = useCallback(() => {
    setStage('enterAffiliateSite');
    close();
  }, [close]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open: type === 'addAffiliateSite' || type === 'editAffiliateSite',
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [type]);
  const formProps = useMemo(() => ({
    initialValues: (() => {
      const isEdit = type === 'editAffiliateSite';
      return {
        Active: isEdit && row ? row.Active : true,
        AffiliateSiteId: isEdit && row ? row.AffiliateSiteId : '',
        Name: isEdit && row ? row.Name : '',
        Address1: isEdit && row ? row.Address1 : '',
        Address2: isEdit && row ? row.Address2 : '',
        City: isEdit && row ? row.City : '',
        StateProvince: isEdit && row ? row.StateProvince : '',
        PostalCode: isEdit && row ? row.PostalCode : '',
        Country: isEdit && row ? row.Country : '',
      };
    })(),
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const updateDoc = {
            collection: 'AffiliateSites',
            writeType: (() => {
              if (type === 'addAffiliateSite') return 'set';
              else if (type === 'editAffiliateSite') return 'update';
              else return null;
            })(),
            docId: (() => {
              if (type === 'addAffiliateSite') return values.AffiliateSiteId;
              else if (type === 'editAffiliateSite') return row.AffiliateSiteId;
              else return null;
            })(),
            docFields: {
              Active: values.Active,
              Name: values.Name.trim(),
              Address1: values.Address1.trim(),
              Address2: values.Address2.trim(),
              City: values.City.trim(),
              StateProvince: values.StateProvince.trim(),
              PostalCode: values.PostalCode.trim(),
              Country: values.Country.trim(),
            },
          };
          await firestoreUtility(updateDoc);
          setStage('success');
        }
        catch (error) {
          setAlert({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      Active: boolean().required(),
      AffiliateSiteId: string().label('Site Id').required().min(3),
      Name: string().required().min(3),
      Address1: string().label('Address 1').required(),
      Address2: string().label('Address 2').notRequired(),
      City: string().required(),
      StateProvince: string().required(),
      PostalCode: string().label('Postal Code').required().matches(postalRegex, 'Postal Code is not properly formatted.'),
      Country: string().required(),
    }),
  }), [row, type]);
  const siteIdProps = useMemo(() => ({
    label: 'Site Id',
    name: 'AffiliateSiteId',
    required: true,
  }), []);
  const countryProps = useMemo(() => {
    return {
      name: 'Country',
      label: 'Country',
      options: countries,
      optionKey: 'value',
      required: true,
    };
  }, []);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({isSubmitting, isValidating, setFieldValue, values}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                {type === 'addAffiliateSite' ? 'Create New' : 'Update'} Affiliate Site
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'enterAffiliateSite'} timeout={{enter: 500, exit: 500}}>
                <DialogContentText>Enter affiliate site details below:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <SelectField name='Active' disabled={Boolean(type === 'addAffiliateSite')} required>
                      <MenuItem key={true} value={true}>
                        True
                      </MenuItem>
                      <MenuItem key={false} value={false}>
                        False
                      </MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField {...siteIdProps} onBlur={() => setFieldValue('AffiliateSiteId', values.AffiliateSiteId.toUpperCase())} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='Name' required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='Address1' label='Address 1' required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='Address2' label='Address 2' required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoComplete {...countryProps} onChange={() => setFieldValue('StateProvince', '')} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoStateProvince country={values.Country} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='City' required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='PostalCode' label='Postal Code' required />
                  </Grid>
                </Grid>
                <Alert text={alert.text} in={alert.in} severity={alert.severity} onClose={() => setAlert({...alert, in: false})} />
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    Affiliate Site successfully {type === 'addAffiliateSite' ? 'added.' : 'updated.'}
                  </Typography>
                </div>
              </Collapse>
            </DialogContent>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color='secondary' disabled={isSubmitting || isValidating}>
                  Cancel
                </Button>
                <Button type='submit' color='primary' disabled={isSubmitting || isValidating}>
                  Submit
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ReceivingSiteModal.propTypes = {
  row: PropTypes.object,
  type: PropTypes.string,
  close: PropTypes.func.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (!prevProps.type && (nextProps.type === 'addAffiliateSite' || nextProps.type === 'editAffiliateSite')) return false;
  else if ((prevProps.type === 'addAffiliateSite' || prevProps.type === 'editAffiliateSite') && !nextProps.type) return false;
  return true;
};
export default React.memo(ReceivingSiteModal, isMemoEqual);

const AutoStateProvince = ({country}) => {
  if (country === 'Canada') {
    return (
      <AutoComplete name='StateProvince' label='Province' options={provinces} optionKey='value' required />
    );
  }
  return (
    <AutoComplete name='StateProvince' label='State' options={states} optionKey='value' required />
  );
};
AutoStateProvince.propTypes = {
  country: PropTypes.string.isRequired,
};
