export const countries = [
  {value: 'United States'},
  {value: 'Canada'},
];
export const states = [
  {value: 'AK'},
  {value: 'AL'},
  {value: 'AR'},
  {value: 'AS'},
  {value: 'AZ'},
  {value: 'CA'},
  {value: 'CO'},
  {value: 'CT'},
  {value: 'DC'},
  {value: 'DE'},
  {value: 'FL'},
  {value: 'GA'},
  {value: 'GU'},
  {value: 'HI'},
  {value: 'IA'},
  {value: 'ID'},
  {value: 'IL'},
  {value: 'IN'},
  {value: 'KS'},
  {value: 'KY'},
  {value: 'LA'},
  {value: 'MA'},
  {value: 'MD'},
  {value: 'ME'},
  {value: 'MI'},
  {value: 'MN'},
  {value: 'MO'},
  {value: 'MS'},
  {value: 'MT'},
  {value: 'NC'},
  {value: 'ND'},
  {value: 'NE'},
  {value: 'NH'},
  {value: 'NJ'},
  {value: 'NM'},
  {value: 'NV'},
  {value: 'NY'},
  {value: 'OH'},
  {value: 'OK'},
  {value: 'OR'},
  {value: 'PA'},
  {value: 'PR'},
  {value: 'RI'},
  {value: 'SC'},
  {value: 'SD'},
  {value: 'TN'},
  {value: 'TX'},
  {value: 'UT'},
  {value: 'VA'},
  {value: 'VI'},
  {value: 'VT'},
  {value: 'WA'},
  {value: 'WI'},
  {value: 'WV'},
  {value: 'WY'},
];
export const provinces = [
  {value: 'AB'},
  {value: 'BC'},
  {value: 'MB'},
  {value: 'NB'},
  {value: 'NL'},
  {value: 'NT'},
  {value: 'NS'},
  {value: 'NU'},
  {value: 'ON'},
  {value: 'PE'},
  {value: 'QC'},
  {value: 'SK'},
  {value: 'YT'},
];
