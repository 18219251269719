import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {Collapse, Formik} from '@kbi/component-library';
const {RadioGroup} = Formik;

const StageHazmat = ({formValues, stage}) => {
  const hazmatCertifiedProps = useMemo(() => ({
    name: 'HazmatCertified',
    required: true,
    label: 'Hazmat Training Certification',
    radios: [
      {value: 'Hazmat', label: 'I certify employee has and maintains the required hazmat training.'},
      {value: 'Non-Hazmat', label: 'No hazmat trained employee. Use an authorized third-party instead.'},
    ],
  }), []);

  return (
    <Collapse in={stage === 'hazmat'}>
      <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Hazmat Certification</Typography>
      <Typography variant='subtitle1' style={{lineHeight: 1.3, marginBottom: '24px', textAlign: 'justify'}}>
        IMPORTANT: Pursuant to strict Department of Transportation regulations, you must accurately declare if the
        employee packaging and offering this battery for shipment is certified and approved to ship hazardous materials.
      </Typography>
      <RadioGroup {...hazmatCertifiedProps} />
    </Collapse>
  );
};

StageHazmat.propTypes = {
  formValues: PropTypes.object.isRequired,
  stage: PropTypes.string.isRequired,
};
export default StageHazmat;
