import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Grid, IconButton, InputAdornment, Typography} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {useFormikContext} from 'formik';
import {Functions} from 'firebaseConfig';
import {countries, provinces, states} from '../../../constants';
import {Collapse, Formik} from '@kbi/component-library';
const {AutoComplete, RadioGroup, TextField} = Formik;

const StagePickup = ({stage}) => {
  const formik = useFormikContext();
  const {currentUser} = useSelector(state => state.auth);
  const {affiliateSites} = useSelector(state => state.firestore);
  const handleDealerSearch = useCallback((uncleanDealerNo) => {
    const getDealerInfo = async () => {
      let cleanDealerNo = null;
      if (uncleanDealerNo.length === 1) cleanDealerNo = '000' + uncleanDealerNo;
      else if (uncleanDealerNo.length === 2) cleanDealerNo = '00' + uncleanDealerNo;
      else if (uncleanDealerNo.length === 3) cleanDealerNo = '0' + uncleanDealerNo;
      else cleanDealerNo = uncleanDealerNo;
      try {
        formik.setStatus({...formik.status, in: false});
        formik.setSubmitting(true);
        const {data} = await Functions.httpsCallable('harleyGetDlrAddress')(cleanDealerNo);
        formik.setValues({...formik.values, DealerNo: cleanDealerNo, LocationFound: true, ...data});
        formik.setSubmitting(false);
      }
      catch (error) {
        if (error.message === 'Dealership Country Not Allowed') {
          formik.setValues({...formik.initialValues, DealerNo: cleanDealerNo});
          formik.setStatus({in: true, severity: 'error', text: 'Dealership is located in a country not serviced by this portal.'});
        }
        else if (error.message === 'Dealership Not Found') {
          formik.setValues({...formik.initialValues, DealerNo: cleanDealerNo});
          formik.setStatus({in: true, severity: 'error', text: 'No results for provided Dealer No. Please check entry and try again.'});
        }
        else {
          formik.setStatus({in: true, severity: 'warning', text: 'Failed to auto populate dealer info. Please enter fields manually.'});
          formik.setFieldValue('LocationFound', true);
        }
        formik.setSubmitting(false);
      }
    };
    if (uncleanDealerNo) getDealerInfo();
    formik.setTouched({});
  }, [formik]);

  const locationTypeProps = useMemo(() => ({
    label: 'Location Type',
    name: 'LocationType',
    radios: [
      {label: 'H-D Dealer', value: 'H-D Net'},
      {label: 'Affiliate', value: 'Affiliate'},
    ],
    row: true,
    onChange: ({form, field}) => {
      form.setFieldValue('DealerNo', '');
      form.setFieldValue('AffiliateId', '');
      form.setFieldValue('DealerName', '');
      form.setFieldValue('AffiliateName', '');
      form.setFieldValue('PickupAddress1', '');
      form.setFieldValue('PickupAddress2', '');
      form.setFieldValue('PickupCity', '');
      form.setFieldValue('PickupStateProvince', '');
      form.setFieldValue('PickupPostalCode', '');
      form.setFieldValue('PickupCountry', '');
      form.setFieldValue('LocationFound', false);
    },
  }), []);
  const affiliateIdProps = useMemo(() => ({
    label: 'Affiliate Id',
    name: 'AffiliateId',
    options: affiliateSites?.list || [],
    optionKey: 'AffiliateSiteId',
    onChange: ({field, form}) => {
      form.setFieldValue('AffiliateName', field.value ? field.value.Name : '');
      form.setFieldValue('PickupAddress1', field.value ? field.value.Address1 : '');
      form.setFieldValue('PickupAddress2', field.value ? field.value.Address2 : '');
      form.setFieldValue('PickupCity', field.value ? field.value.City : '');
      form.setFieldValue('PickupStateProvince', field.value ? field.value.StateProvince : '');
      form.setFieldValue('PickupPostalCode', field.value ? field.value.PostalCode : '');
      form.setFieldValue('PickupCountry', field.value ? field.value.Country : '');
      form.setFieldValue('LocationFound', field.value ? true : false);
    },
  }), [affiliateSites]);
  const dealerNoAdminProps = useMemo(() => {
    const DealerNo = formik.values.DealerNo;
    return {
      helperText: 'Look up Dealer No. to continue.',
      label: 'Dealer No.',
      name: 'DealerNo',
      required: true,
      onChange: ({event, form}) => {
        form.setValues({
          ...form.initialValues,
          DealerNo: event.target.value,
          LocationFound: false,
          DealerName: '',
          PickupAddress1: '',
          PickupAddress2: '',
          PickupCity: '',
          PickupStateProvince: '',
          PickupPostalCode: '',
          PickupCountry: '',
        });
      },
      onKeyDown: event => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Enter', 'Tab'];
        if (!allowedKeys.includes(event.key)) event.preventDefault();
        if (event.key === 'Enter') handleDealerSearch(DealerNo);
      },
      InputProps: {endAdornment: <InputAdornment position="end">
        <IconButton onClick={() => handleDealerSearch(DealerNo)} disabled={formik.isSubmitting} size='small'><Search /></IconButton>
      </InputAdornment>},
    };
  }, [formik, handleDealerSearch]);
  const countryProps = useMemo(() => {
    return {
      disabled: !formik.values.LocationFound,
      name: 'PickupCountry',
      label: 'Country',
      options: countries,
      optionKey: 'value',
      required: true,
    };
  }, [formik.values.LocationFound]);

  return (
    <Collapse in={stage === 'pickup'}>
      <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Pickup Information</Typography>
      <Typography variant='subtitle1' style={{marginBottom: '8px'}}>Confirm details for the battery pickup location.</Typography>
      <Grid container spacing={2}>
        {['Admin', 'Harley', 'KBI'].includes(currentUser.group) && <Grid item xs={12} style={{paddingBottom: '0px'}}>
          <RadioGroup {...locationTypeProps} />
        </Grid>}
        <Grid item xs={12} sm={6}>
          {['Admin', 'Harley', 'KBI'].includes(currentUser.group) && formik.values.LocationType === 'H-D Net' && <TextField {...dealerNoAdminProps} />}
          {['Admin', 'Harley', 'KBI'].includes(currentUser.group) && formik.values.LocationType === 'Affiliate' && <AutoComplete {...affiliateIdProps} />}
          {currentUser.group === 'H-D Net' && <TextField name='DealerNo' label='Dealer No.' required disabled />}
          {currentUser.group === 'Affiliate' && <TextField name='AffiliateId' label='Affiliate Id' required disabled />}
        </Grid>
        <Grid item xs={12} sm={6}>
          {['Admin', 'Harley', 'KBI'].includes(currentUser.group) && formik.values.LocationType === 'H-D Net' && <TextField name='DealerName' label='Dealer Name' required disabled />}
          {['Admin', 'Harley', 'KBI'].includes(currentUser.group) && formik.values.LocationType === 'Affiliate' && <TextField name='AffiliateName' label='Affiliate Name' required disabled />}
          {currentUser.group === 'H-D Net' && <TextField name='DealerName' label='Dealer Name' required disabled={formik.values.DealerNo !== '2198'} />}
          {currentUser.group === 'Affiliate' && <TextField name='AffiliateName' label='Affiliate Name' required disabled />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupAddress1' label='Pickup Address 1' required disabled={!formik.values.LocationFound} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupAddress2' label='Pickup Address 2' disabled={!formik.values.LocationFound} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoComplete {...countryProps} onChange={(stuff) => formik.setFieldValue('PickupStateProvince', '')} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoStateProvince country={formik.values.PickupCountry} locationFound={formik.values.LocationFound} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupCity' label='City' required disabled={!formik.values.LocationFound} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupPostalCode' label='Postal Code' required disabled={!formik.values.LocationFound} />
        </Grid>
      </Grid>
    </Collapse>
  );
};

StagePickup.propTypes = {
  stage: PropTypes.string.isRequired,
};
export default StagePickup;

const AutoStateProvince = ({country, locationFound}) => {
  if (country === 'Canada') {
    return (
      <AutoComplete name='PickupStateProvince' label='Province' options={provinces} optionKey='value' required disabled={!locationFound} />
    );
  }
  return (
    <AutoComplete name='PickupStateProvince' label='State' options={states} optionKey='value' required disabled={!locationFound} />
  );
};
AutoStateProvince.propTypes = {
  country: PropTypes.string.isRequired,
  locationFound: PropTypes.bool.isRequired,
};
