import React from 'react';
import {Link} from 'react-router-dom';
import {Button, ButtonGroup, Paper, Typography} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const MainView = () => {
  const styles = useStyles();
  return (
    <Paper className={styles.paper} elevation={2}>
      <Typography align='center' variant='h4' gutterBottom>Administrative Settings</Typography>
      <Settings color='primary' style={{fontSize: '10em', width: '100%'}} />
      <Typography align='center' variant='h6'>What Setting Do You Want To Update?</Typography>
      <ButtonGroup variant='text' color='primary'>
        <Button component={Link} to='/portal/admin-settings/battery-types'>Battery Types</Button>
        <Button component={Link} to='/portal/admin-settings/receiving-sites'>Receiving Sites</Button>
        <Button component={Link} to='/portal/admin-settings/affiliate-sites'>Affiliate Sites</Button>
        <Button component={Link} to='/portal/admin-settings/portal-users'>Portal Users</Button>
      </ButtonGroup>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `16px 24px 24px`,
  },
}));
export default MainView;
