import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Grid, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {object, string} from 'yup';
import {firestoreUtility} from 'utilities';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {FormButton, FormikForm, SubmitButton, TextField} = Formik;

const ModalAddAdminNote = ({close, open, selectedReturn}) => {
  const {currentUser} = useSelector(state => state.auth);
  const [stage, setStage] = useState('addAdminNote');
  const handleClose = useCallback(() => {
    setStage('addAdminNote');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {Note: ''},
    onSubmit: (values, actions) => {
      const Notes = [
        ...selectedReturn.Notes,
        {Note: values.Note, CreatedBy: currentUser.name, CreatedOn: new Date()},
      ];
      (async () => {
        try {
          const updateDoc = {
            collection: 'BatteryReturns',
            writeType: 'update',
            docId: selectedReturn.BatteryReturnId,
            docFields: {Notes},
          };
          await firestoreUtility(updateDoc);
          setStage('success');
          setTimeout(() => actions.setSubmitting(false), 750);
        }
        catch (error) {
          actions.setStatus({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      Note: string().required(),
    }),
  }), [currentUser.name, selectedReturn.BatteryReturnId, selectedReturn.Notes]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({isSubmitting, isValidating, setFieldValue, setStatus, status, values}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Add Admin Note
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'addAdminNote'}>
                <DialogContentText>Enter additional notes about this battery return:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField name='Note' required multiline={true} />
                  </Grid>
                </Grid>
                <Alert text={status.text} in={status.in} severity={status.severity} onClose={() => setStatus({...status, in: false})} />
              </Collapse>
              <Collapse in={stage === 'success'}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    New admin note successfully added.
                  </Typography>
                </div>
              </Collapse>
            </DialogContent>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='primary' variant='text'>Close</FormButton>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='secondary' variant='text'>
                  Cancel
                </FormButton>
                <SubmitButton color='primary' variant='text' />
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalAddAdminNote.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedReturn: PropTypes.object.isRequired,
};
export default ModalAddAdminNote;
