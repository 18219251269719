import React from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import {LocalShipping} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const ViewNoReturns = ({setModal}) => {
  const styles = useStyles();
  return (
    <Paper className={styles.paper} elevation={2}>
      <Typography variant="h4" gutterBottom>
        Battery Return Request
      </Typography>
      <LocalShipping style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
      <Typography variant="h6" gutterBottom>
        No Previous Returns Found
      </Typography>
      <Button variant="outlined" color="primary" onClick={() => setModal({type: 'newBatteryPickup'})}>
        Schedule First Return
      </Button>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `16px 24px 24px`,
  },
}));
ViewNoReturns.propTypes = {
  setModal: PropTypes.func.isRequired,
};
export default ViewNoReturns;
