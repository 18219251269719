import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Button, Divider, Grid, Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Cancel, VerifiedUser, Print} from '@material-ui/icons';
import moment from 'moment';

const PrintCertificate = ({selectedReturn}) => {
  const styles = useStyles();
  const cancelButtonProps = useMemo(() => ({
    className: 'HidePrint',
    component: Link,
    size: 'small',
    style: {marginLeft: '8px'},
    to: `/portal/battery-returns/details/${selectedReturn.BatteryReturnId}`,
    variant: 'outlined',
  }), [selectedReturn.BatteryReturnId]);
  const fieldButtonProps = useMemo(() => ({
    className: 'HidePrint',
    size: 'small',
    style: {marginLeft: '0px'},
    variant: 'outlined',
    onClick: () => window.print(),
  }), []);

  return (
    <Fragment>
      <div style={{margin: '24px 0px'}}>
        <Button {...fieldButtonProps}>
          <Print className={styles.extendedIcon} />Start Print
        </Button>
        <Button {...cancelButtonProps}>
          <Cancel className={styles.extendedIcon} />Cancel Print
        </Button>
      </div>
      <Paper className={styles.paper} elevation={2} id='RemoveElevation'>
        <Typography variant='h4' className={styles.typeHeader}>Certificate of Acceptance</Typography>
        <VerifiedUser style={{fontSize: '12em', fill: '#002856', padding: '40px 0px 0px 0px', width: '100%'}} />
        <Divider style={{backgroundColor: '#002856', height: '3px', margin: '40px 0px', width: '100%'}} />
        <Grid spacing={0} container>
          <Grid item xs={4} style={{textAlign: 'center'}}>
            <Typography variant='h6'>Battery VIN</Typography>
            <Typography variant='body2'>{selectedReturn.BatteryVin}</Typography>
          </Grid>
          <Grid item xs={4} style={{textAlign: 'center'}}>
            <Typography variant='h6'>Part Number</Typography>
            <Typography variant='body2'>{selectedReturn.Battery.PartNo}</Typography>
          </Grid>
          <Grid item xs={4} style={{textAlign: 'center'}}>
            <Typography variant='h6'>Receipt Date</Typography>
            <Typography variant='body2'>{moment(selectedReturn.Date.Received).format('MM/DD/YYYY')}</Typography>
          </Grid>
        </Grid>
        <Grid spacing={0} container style={{paddingTop: '40px'}}>
          <Grid item xs={4} style={{textAlign: 'center'}}>
            <Typography variant='h6'>Scheduled By</Typography>
            <Typography variant='body2'>{selectedReturn.Contact.Name}</Typography>
            <Typography variant='body2'>{selectedReturn.Contact.Phone}</Typography>
            <Typography variant='body2'>{selectedReturn.Contact.Email}</Typography>
          </Grid>
          <Grid item xs={4} style={{textAlign: 'center'}}>
            <Typography variant='h6'>Pickup Location</Typography>
            <Typography variant='body2' style={{textTransform: 'capitalize'}}>
              {selectedReturn.LocationType === 'H-D Net' && `${selectedReturn.DealerName.toLowerCase()} (${selectedReturn.DealerNo})`}
              {selectedReturn.LocationType === 'Affiliate' && `${selectedReturn.AffiliateName}`}
            </Typography>
            <Typography variant='body2' style={{textTransform: 'capitalize'}}>
              {selectedReturn.PickupLocation.Address1.toLowerCase()}
            </Typography>
            {selectedReturn.PickupLocation.Address2 && (
              <Typography variant='body2' style={{textTransform: 'capitalize'}}>
                {selectedReturn.PickupLocation.Address2.toLowerCase()}
              </Typography>
            )}
            <Typography variant='body2' style={{textTransform: 'capitalize'}}>
              {selectedReturn.PickupLocation.City.toLowerCase()}, {selectedReturn.PickupLocation.StateProvince} {selectedReturn.PickupLocation.PostalCode}
            </Typography>
            <Typography variant='body2'>
              {selectedReturn.PickupLocation.Country}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{textAlign: 'center'}}>
            <Typography variant='h6'>Receiving Location</Typography>
            <Typography variant='body2'>{selectedReturn.ReceivingSite.Name}</Typography>
            <Typography variant='body2'>{selectedReturn.ReceivingSite.Address1}</Typography>
            {selectedReturn.ReceivingSite.Address2 && <Typography variant='body2'>{selectedReturn.ReceivingSite.Address2}</Typography>}
            <Typography variant='body2'>
              {selectedReturn.ReceivingSite.City}, {selectedReturn.ReceivingSite.StateProvince} {selectedReturn.ReceivingSite.PostalCode}
            </Typography>
            <Typography variant='body2'>
              {selectedReturn.ReceivingSite.Country}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{backgroundColor: '#002856', height: '3px', margin: '40px 0px', width: '100%'}} />
        <Typography variant='body2' style={{fontSize: '.95rem', textAlign: 'justify', lineHeight: 1.4}}>
          This document certifies that the above described battery was received and accepted by an approved Harley-Davidson Motor Company
          return facility. Further, this document certifies that the information contained herein is true, accurate and complete.
        </Typography>
        <Typography variant='body2' style={{fontSize: '.95rem', textAlign: 'justify', lineHeight: 1.4, marginTop: '24px'}}>
          This document has been issued by xEV Strategies, a division of Kinsbursky Bros. Supply, Inc. For more information about this document or the
          returned battery, contact an xEV Strategies representative by calling 800-502-9258 or emailing hd-support@xevstrategies.com.
        </Typography>
      </Paper>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: '8px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginTop: '0px',
    overflowX: 'hidden',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '850px',
    minHeight: '950px',
  },
  typeHeader: {
    width: '100%',
    marginTop: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}));
PrintCertificate.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default PrintCertificate;
