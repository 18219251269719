import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {AttachMoney} from '@material-ui/icons';
import ModalFinalizeAmount from './ModalFinalizeAmount';

const ViewFinalizeAmount = ({selectedReturn}) => {
  const [modal, setModal] = useState(false);

  return (
    <Fragment>
      {selectedReturn.Status === 'Battery Received' && selectedReturn?.Accounting?.Status === 'Open' && (
        <ListItem button onClick={() => setModal(true)} style={{padding: '8px'}}>
          <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary='Finalize Amount' />
        </ListItem>
      )}
      <ModalFinalizeAmount open={modal} close={() => setModal(false)} selectedReturn={selectedReturn} />
    </Fragment>
  );
};

ViewFinalizeAmount.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewFinalizeAmount;
