import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import moment from 'moment';
import {date, object} from 'yup';
import {firestoreUtility} from 'utilities';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {DateField, FormButton, FormikForm, SubmitButton} = Formik;

const ModalSetReminder = ({close, open, selectedReturn}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [stage, setStage] = useState('setDate');
  const handleClose = useCallback(() => {
    setStage('setDate');
    setConfirmDelete(false);
    close();
  }, [close]);
  const handleDelete = useCallback(async ({setStatus}) => {
    if (!confirmDelete) setConfirmDelete(true);
    if (confirmDelete === true) {
      try {
        const updateDocument = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: selectedReturn.BatteryReturnId,
          docFields: {'Date.Reminder': null},
        };
        await firestoreUtility(updateDocument);
        setStage('success');
      }
      catch (error) {
        setStatus({in: true, severity: 'error', text: error.message});
      }
    }
  }, [confirmDelete, selectedReturn.BatteryReturnId]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {
      Reminder: selectedReturn.Reminder ? moment(selectedReturn.Reminder).format('YYYY-MM-DD') : moment().add(3, 'days').format('YYYY-MM-DD'),
    },
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const updateDocument = {
            collection: 'BatteryReturns',
            writeType: 'update',
            docId: selectedReturn.BatteryReturnId,
            docFields: {'Date.Reminder': moment(values.Reminder).toDate()},
          };
          await firestoreUtility(updateDocument);
          setStage('success');
          setTimeout(() => actions.setSubmitting(false), 750);
        }
        catch (error) {
          actions.setStatus({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      Reminder: date().required(),
    }),
  }), [selectedReturn.BatteryReturnId, selectedReturn.Reminder]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({setStatus, status, isSubmitting, isValidating, setFieldValue, values}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'}>
                Set Reminder
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'setDate'}>
                <DateField name='Reminder' />
              </Collapse>
              <Collapse in={stage === 'success'}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    Reminder has been successfully updated.
                  </Typography>
                </div>
              </Collapse>
              <Alert text={status.text} in={status.in} severity={status.severity} onClose={() => setStatus({...status, in: false})} />
            </DialogContent>
            <Collapse in={stage !== 'success'}>
              <DialogActions>
                <div style={{justifyContent: 'space-between', width: '100%'}}>
                  {selectedReturn?.Date?.Reminder && <FormButton color='secondary' variant='text' onClick={() => handleDelete({setStatus, status})}>
                    {!confirmDelete ? 'Delete' : 'Confirm'}
                  </FormButton>}
                  <div style={{float: 'right'}}>
                    <FormButton onClick={handleClose} color='secondary' variant='text'>Cancel</FormButton>
                    <SubmitButton color='primary' variant='text'>Set Reminder</SubmitButton>
                  </div>
                </div>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='primary' variant='text'>Close</FormButton>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalSetReminder.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedReturn: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalSetReminder, isMemoEqual);
