import React from 'react';
import {Collapse, DialogTitle as MuiDialogTitle} from '@material-ui/core';
import PropTypes from 'prop-types';

const DialogTitle = ({stage}) => {
  return (
    <MuiDialogTitle>
      <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
        New Return Request
      </Collapse>
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  stage: PropTypes.string.isRequired,
};
export default DialogTitle;
