import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from '@material-ui/core';
import {Grid, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import moment from 'moment';
import {date, object, string, array} from 'yup';
import {firestoreUtility, julianDateRegex, postalRegex, phoneRegex} from 'utilities';
import EditPickupRequest from './EditPickupRequest';
import EditPickupSchedule from './EditPickupSchedule';
import EditBatteryReceipt from './EditBatteryReceipt';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {FormButton, FormikForm, SubmitButton, SelectField} = Formik;

const ModalEditReturn = ({close, open, selectedReturn}) => {
  const [alert, setAlert] = useState({text: '', in: false, severity: 'warning'});
  const [stage, setStage] = useState('chooseDetails');
  const handleClose = useCallback(() => {
    setStage('chooseDetails');
    close();
  }, [close]);
  const handleSectionChange = useCallback(e => {
    if (e.event.target.value) {
      setStage(e.event.target.value);
      // setDetailType(e.event.target.value);
    };
  }, []);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]); ;
  const formProps = useMemo(() => ({
    initialValues: {
      DetailsSection: '',
      PickupAddress1: selectedReturn.PickupLocation?.Address1,
      PickupAddress2: selectedReturn.PickupLocation?.Address2,
      PickupCity: selectedReturn.PickupLocation?.City,
      PickupStateProvince: selectedReturn.PickupLocation?.StateProvince,
      PickupPostalCode: selectedReturn.PickupLocation?.PostalCode,
      PickupCountry: selectedReturn.PickupLocation?.Country,
      ContactName: selectedReturn.Contact?.Name,
      ContactEmail: selectedReturn.Contact?.Email,
      ContactPhone: selectedReturn.Contact?.Phone,
      ContactFax: selectedReturn.Contact?.Fax,
      BatteryPartNo: selectedReturn.Battery?.PartNo,
      BatteryMfgSerialNo: selectedReturn.Battery?.MfgSerialNo,
      BatteryJulianDate: selectedReturn.Battery?.JulianDate,
      BatteryName: selectedReturn.Battery?.Name,
      BatteryShippedSerialNo: selectedReturn.Battery?.SerialShipped,
      BatteryVinAvailable: selectedReturn.Battery?.VinAvailable,
      BatteryVinNumber: selectedReturn.BatteryVin,
      BatteryWarranty: selectedReturn.Battery?.Warranty,
      BatteryWarrantyAuth: selectedReturn.Battery?.WarrantyAuth,
      BatteryHazards: selectedReturn.Battery?.Hazards,
      // Shipment
      Carrier: selectedReturn.ShipmentDetails?.Carrier,
      PickupDate: moment(selectedReturn.ShipmentDetails?.PickupDate).format('YYYY-MM-DD'),
      ShippingPaper: selectedReturn.ShipmentDetails?.ShippingPaper,
      ShippingPO: selectedReturn.ShipmentDetails?.ShippingPO,
      ReceivingSiteRef: selectedReturn.ReceivingSite?.Ref,
      ReceivingSiteName: selectedReturn.ReceivingSite?.Name,
      ReceivingSiteCountry: selectedReturn.ReceivingSite?.Country,
      ReceivingSiteAddress1: selectedReturn.ReceivingSite?.Address1,
      ReceivingSiteAddress2: selectedReturn.ReceivingSite?.Address2,
      ReceivingSiteCity: selectedReturn.ReceivingSite?.City,
      ReceivingStateProvince: selectedReturn.ReceivingSite?.StateProvince,
      ReceivingSitePostalCode: selectedReturn.ReceivingSite?.PostalCode,
      // Receipt
      DateReceived: moment(selectedReturn.Date.Received).format('YYYY-MM-DD'),
      SerialNoShipped: selectedReturn.Battery?.SerialNoShipped,
      SerialNoReceived: selectedReturn.Battery?.SerialNoReceived,
    },
    onSubmit: async (values, actions) => {
      let updateDoc = {};
      if (stage === 'Pickup Request') {
        updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: selectedReturn.BatteryReturnId,
          docFields: {
            'PickupLocation': {
              Address1: values.PickupAddress1,
              Address2: values.PickupAddress2,
              City: values.PickupCity,
              StateProvince: values.PickupStateProvince,
              PostalCode: values.PickupPostalCode,
              Country: values.PickupCountry,
            },
            'Contact': {
              Name: values.ContactName,
              Email: values.ContactEmail,
              Phone: values.ContactPhone,
              Fax: values.ContactFax,
            },
            'Battery.PartNo': values.BatteryPartNo,
            'Battery.MfgSerialNo': values.BatteryMfgSerialNo,
            'Battery.JulianDate': values.BatteryJulianDate,
            'Battery.Name': values.BatteryName,
            'Battery.SerialShipped': `${values.BatteryPartNo}${values.BatteryMfgSerialNo.toUpperCase()}${values.BatteryJulianDate}`,
            'Battery.VinAvailable': values.BatteryVinAvailable,
            'Battery.Warranty': values.BatteryWarranty,
            'Battery.WarrantyAuth': values.BatteryWarrantyAuth,
            'Battery.Hazards': values.BatteryHazards,
            'BatteryVin': values.BatteryVinNumber,
          },
        };
      }
      else if (stage === 'Pickup Schedule') {
        updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: selectedReturn.BatteryReturnId,
          docFields: {
            ShipmentDetails: {
              Carrier: values.Carrier,
              PickupDate: moment(values.PickupDate).toDate(),
              ShippingPaper: values.ShippingPaper,
              ShippingPO: values.ShippingPO,
            },
            ReceivingSite: {
              Name: values.ReceivingSiteName,
              Country: values.ReceivingSiteCountry,
              Address1: values.ReceivingSiteAddress1,
              Address2: values.ReceivingSiteAddress2,
              City: values.ReceivingSiteCity,
              StateProvince: values.ReceivingStateProvince,
              PostalCode: values.ReceivingSitePostalCode,
            },
          },
        };
      }
      else if (stage === 'Battery Receipt') {
        updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: selectedReturn.BatteryReturnId,
          docFields: {
            'Date.Received': moment(values.DateReceived).toDate(),
            'Battery.SerialNoShipped': values.SerialNoShipped,
            'Battery.SerialNoReceived': values.SerialNoReceived,
          },
        };
      }
      try {
        await firestoreUtility(updateDoc);
        setTimeout(() => actions.resetForm(), 250);
        setStage('success');
        setAlert({...alert, in: false});
      }
      catch (error) {
        setAlert({in: true, severity: 'error', text: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: () => {
      if (stage === 'chooseDetails') {
        return object().shape({
          DetailsSection: string().required(),
        });
      }
      else if (stage === 'Pickup Request') {
        return object().shape({
          PickupAddress1: string().label('Pickup Address 1').required(),
          PickupAddress2: string().label('Pickup Address 2').notRequired(),
          PickupCity: string().label('City').required(),
          PickupStateProvince: string().label('State').required(),
          PickupPostalCode: string().required().matches(postalRegex, 'Postal Code is improperly formatted. (e.g. #####)'),
          PickupCountry: string().label('Country').required(),
          ContactName: string().label('Name').required(),
          ContactEmail: string().label('Email').required().email(),
          ContactPhone: string().label('Phone').required().matches(phoneRegex, 'Not properly formatted (e.g. 555-555-5555)'),
          ContactFax: string().label('Fax').notRequired().matches(phoneRegex, 'Not properly formatted (e.g. 555-555-5555)'),
          BatteryPartNo: string().label('Part No.').required(),
          BatteryMfgSerialNo: string().label('Serial No.').required(),
          BatteryJulianDate: string().label('Julian Date').required().matches(julianDateRegex, 'Not properly formatted (e.g. YYDDD)'),
          BatteryName: string().label('Battery Name').notRequired(),
          BatteryShippedSerialNo: string().label('Shipped Serial No.').notRequired(),
          BatteryVinAvailable: string().label('VIN Available').when('BatteryWarranty', {
            is: 'Yes',
            then: string().oneOf(['Yes'], 'VIN required for warranty returns'),
            otherwise: string().notRequired(),
          }),
          BatteryVinNumber: string().label('VIN Number').when('BatteryVinAvailable', {
            is: 'Yes',
            then: string().required().min(17),
            otherwise: string().notRequired(),
          }),
          BatteryWarranty: string().label('Warranty Return').required(),
          BatteryWarrantyAuth: string().label('Warranty Auth No.').when('BatteryWarranty', {
            is: 'Yes',
            then: string().required().min(6, 'Auth No. must be at least 6 characters'),
            otherwise: string().notRequired(),
          }),
          BatteryHazards: array().required(`Battery Hazards is a required field. Select 'None of the above' if no hazards identified.`),
        });
      }
      else if (stage === 'Pickup Schedule') {
        return object().shape({
          Carrier: string().required(),
          PickupDate: date().label('Pickup Date').required(),
          ShippingPaper: string().label('Shipping Paper No.').required(),
          ShippingPO: string().label('Shipping PO No.'),
        });
      }
      else if (stage === 'Battery Receipt') {
        return object().shape({
          DateReceived: date().label('Date Received').required().max(new Date(), 'Date Requested cannot be set in the future'),
          SerialNoReceived: string().label('Serial Number').required().min(3),
        });
      }
    },
  }), [alert, selectedReturn, stage]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => {
          return (<Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'}>Edit Return</Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'chooseDetails'}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SelectField name={'DetailsSection'} label='Details Section' required onChange={handleSectionChange}>
                      {selectedReturn.PickupLocation.Address1 && <MenuItem value={'Pickup Request'}>Pickup Request</MenuItem>}
                      {selectedReturn.ShipmentDetails?.Carrier && <MenuItem value={'Pickup Schedule'}>Pickup Schedule</MenuItem>}
                      {selectedReturn.Date.Received && <MenuItem value={'Battery Receipt'}>Battery Receipt</MenuItem>}
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'Pickup Request'}>
                <EditPickupRequest formik={formik} />
              </Collapse>
              <Collapse in={stage === 'Pickup Schedule'}>
                <EditPickupSchedule formik={formik} />
              </Collapse>
              <Collapse in={stage === 'Battery Receipt'}>
                <EditBatteryReceipt formik={formik} />
              </Collapse>
              <Collapse in={stage === 'success'}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    Battery return was successfully updated.
                  </Typography>
                </div>
              </Collapse>
              <Alert text={alert.text} in={alert.in} severity={alert.severity} onClose={() => setAlert({...alert, in: false})} />
            </DialogContent>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='primary' variant='text'>Close</FormButton>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success' }>
              <DialogActions>
                <FormButton onClick={handleClose} color='secondary' variant='text'>Cancel</FormButton>
                {stage !== 'chooseDetails' && <SubmitButton color='primary' variant='text' />}
              </DialogActions>
            </Collapse>
          </Fragment>)
          ;
        }}
      </FormikForm>
    </Dialog>
  );
};

ModalEditReturn.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedReturn: PropTypes.object.isRequired,
};
export default ModalEditReturn;
