import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Redirect, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@material-ui/core';
import {AsyncLoader} from 'components';
import {AffiliateSiteTable, BatteryTypeTable, MainView, PortalUserTable, ReceivingSiteTable} from './AdminSettings/index';
import {acGetPortalUsersList} from 'state/actions/auth';
import {listenAdminSettings} from 'state/actions/firestore';

const AdminSettings = ({history}) => {
  const {subview} = useParams();
  const dispatch = useDispatch();
  const {currentUser} = useSelector(state => state.auth);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenAdminSettings({currentUser, dispatch}), [currentUser]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(acGetPortalUsersList({currentUser, dispatch}), [currentUser]);

  if (!currentUser) return <AsyncLoader />;
  if (!['Admin', 'Harley', 'KBI'].includes(currentUser.group)) return <Redirect to={'/portal/battery-returns'} />;
  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {!subview && <MainView />}
          {subview === 'battery-types' && <BatteryTypeTable />}
          {subview === 'receiving-sites' && <ReceivingSiteTable />}
          {subview === 'affiliate-sites' && <AffiliateSiteTable />}
          {subview === 'portal-users' && <PortalUserTable />}
        </Grid>
      </Grid>
    </Fragment>
  );
};

AdminSettings.propTypes = {
  history: PropTypes.object.isRequired,
};
export default AdminSettings;
