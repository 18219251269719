import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import {Collapse, Formik} from '@kbi/component-library';
const {CheckboxGroup} = Formik;

const StageHazards = ({stage}) => {
  const hazardProps = useMemo(() => {
    const batteryHazards = [
      {
        label: 'Warm to the touch (i.e. above room temperature).',
        value: 'Warm to the touch (i.e. above room temperature).',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Signs of heat damage, such as burn marks or warping.',
        value: 'Signs of heat damage, such as burn marks or warping.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Exposed/unprotected wires capable of causing an electric shock.',
        value: 'Exposed/unprotected wires capable of causing an electric shock.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Signs of damage, such as dented, breached, or swollen components.',
        value: 'Signs of damage, such as dented, breached, or swollen components.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Any other indicator that it may not be safe to transport as-is.',
        value: 'Any other indicator that it may not be safe to transport as-is.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'None of the above.',
        value: 'None of the above.',
      },
    ];
    return {
      boxes: batteryHazards,
      label: ' ',
      name: 'BatteryHazards',
      onChange: ({event, form, field, meta}) => {
        const {value} = event.target;
        const noneSelected = value === 'None of the above.';
        let formHazards = form.values.BatteryHazards;
        if (formHazards.includes(value) && !noneSelected) formHazards = formHazards.filter(hazard => value !== hazard && value !== 'None of the above.');
        else if (!formHazards.includes(value) && noneSelected) formHazards = ['None of the above.'];
        else if (formHazards.includes(value) && noneSelected) formHazards = [];
        else if (!formHazards.includes(value) && !noneSelected) {
          formHazards.push(event.target.value);
          formHazards = formHazards.filter(hazard => hazard !== 'None of the above.');
        }
        form.setFieldValue('BatteryHazards', formHazards);
      },
    };
  }, []);

  return (
    <Collapse in={stage === 'hazards'}>
      <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Battery Hazards</Typography>
      <Typography variant='body1' style={{lineHeight: 1.1, marginBottom: '16px'}}>Check whether any of the following apply to the battery.</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckboxGroup {...hazardProps} />
        </Grid>
      </Grid>
    </Collapse>
  );
};

StageHazards.propTypes = {
  stage: PropTypes.string.isRequired,
};
export default StageHazards;
