import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button, Paper, Typography} from '@material-ui/core';
import {AddBox, Search} from '@material-ui/icons';
import {Table} from '@kbi/component-library';

const ViewReturnsTable = (props) => {
  const {setModal} = props;
  const {currentUser} = useSelector(state => state.auth);
  const {batteryReturns} = useSelector(state => state.firestore);
  const history = useHistory();

  const btnNewBatteryReturn = useMemo(() => ({
    color: 'primary',
    startIcon: <AddBox />,
    style: {marginTop: '16px', marginBottom: '16px'},
    variant: 'outlined',
    onClick: () => setModal({type: 'newBatteryPickup'}),
  }), [setModal]);
  const tableProps = useMemo(() => ({
    actionsPerRow: [
        {icon: Search, tooltip: 'View Battery Return', onClick: ({event, rowData, rowIndex}) => history.push(`/portal/battery-returns/details/${rowData.BatteryReturnId}`)}, // eslint-disable-line
    ],
    columns: (() => {
      if (['Admin', 'Harley', 'KBI'].includes(currentUser.group)) {
        return [
          {Header: 'Return No.', accessor: 'ReturnNo', type: 'numeric'},
          {Header: 'Requested', accessor: 'Date.Requested', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
          {Header: 'Location', accessor: 'Location'},
          {Header: 'Reminder', accessor: 'Date.Reminder', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
          {Header: 'Status', accessor: 'Status'},
          {Header: 'Accounting', accessor: 'Accounting.Status'},
          {Header: 'Battery', accessor: 'Battery.Name'},
          {Header: 'Part No.', accessor: 'Battery.PartNo'},
          {Header: 'VIN', accessor: 'BatteryVin'},
        ];
      }
      else {
        return [
          {Header: 'Return No.', accessor: 'ReturnNo', defaultSort: 'desc', type: 'numeric'},
          {Header: 'Requested', accessor: 'Date.Requested', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
          {Header: 'Status', accessor: 'Status'},
          {Header: 'Battery', accessor: 'Battery.Name'},
          {Header: 'Part No.', accessor: 'Battery.PartNo'},
          {Header: 'VIN', accessor: 'BatteryVin'},
        ];
      }
    })(),
    data: batteryReturns.list ? batteryReturns.list : [],
    sortInitial: [{id: 'ReturnNo', desc: true}],
    isLoading: !batteryReturns.list,
  }), [batteryReturns.list, currentUser.group, history]);

  return (
    <Fragment>
      <Button {...btnNewBatteryReturn}>New Battery Return</Button>
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>Battery Returns</Typography>
        <Table {...tableProps} />
      </Paper>
    </Fragment>
  );
};

ViewReturnsTable.propTypes = {
  setModal: PropTypes.func.isRequired,
};
export default ViewReturnsTable;
