import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {Portal, SignInSSO, SignInUser} from './views';
import {ErrorBoundary, PrivateRoute} from './components';
import {Auth} from 'firebaseConfig.js';
// Import Redux functionality
import {Provider} from 'react-redux';
import {store} from './state/store';

// Import Design Elements
import CssBaseline from '@material-ui/core/CssBaseline';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import './index.css';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0a3982',
      light: '#4b63b2',
      dark: '#001555',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c62828',
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 4,
  },
});

function idleRefresh() {
  // Got this code from: https://stackoverflow.com/questions/667555/how-to-detect-idle-time-in-javascript-elegantly
  let timeout = null;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer; // catches touchscreen presses as well
  window.ontouchstart = resetTimer; // catches touchscreen swipes as well
  window.onclick = resetTimer; // catches touchpad clicks as well
  window.onkeypress = resetTimer;
  window.addEventListener('scroll', resetTimer, true); // improved; see comments

  function logoutAndReload() {
    Auth.signOut();
    window.location.replace('https://hd.xevstrategies.com/signin');
  }

  function resetTimer() {
    clearTimeout(timeout);
    timeout = setTimeout(logoutAndReload, 28000000); // 8 hours in milliseconds
  }
}
idleRefresh();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <ErrorBoundary>
              <Switch>
                <PrivateRoute path='/portal' Component={Portal} />
                <Route path='/signin/:userType' component={SignInUser} />
                <Route exact path='/signin/' component={SignInUser} />
                <Route exact path='/sso/:jwt' component={SignInSSO} />
                <Redirect exact to='/portal' />
              </Switch>
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }
  componentDidCatch(error, info) {
    console.log('componentDidCatch', {error, info});
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
