import React, {Fragment, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Paper, Typography} from '@material-ui/core';
import {AddBox, Edit, More} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {BatteryTypeModal} from './index';

const BatteryTypeTable = (props) => {
  const [modal, setModal] = useState({type: null, row: null});
  const {batteryTypes} = useSelector(state => state.firestore);

  const btnMore= useMemo(() => ({
    color: 'primary',
    component: Link,
    style: {margin: '16px 0px'},
    startIcon: <More />,
    to: '/portal/admin-settings/',
    variant: 'outlined',
  }), []);
  const btnAddBatteryType = useMemo(() => ({
    color: 'primary',
    startIcon: <AddBox />,
    style: {marginLeft: '16px'},
    variant: 'outlined',
    onClick: () => setModal({type: 'addBatteryType'}),
  }), []);
  const tableProps = useMemo(() => ({
    actionsPerRow: [
        {icon: Edit, tooltip: 'Edit Battery Type', onClick: ({event, rowData, rowIndex}) => setModal({type: 'editBatteryType', row: rowData})}, // eslint-disable-line
    ],
    columns: [
      {Header: 'Active', accessor: 'Active', type: 'boolean'},
      {Header: 'Name', accessor: 'Name'},
      {Header: 'Part Number', accessor: 'PartNumber'},
      {Header: 'Dimensions', accessor: 'Dimensions'},
      {Header: 'Rate', accessor: 'Rate'},
    ],
    data: batteryTypes.list ? batteryTypes.list : [],
    disableFilters: true,
    sortInitial: [{id: 'Name'}],
    isLoading: !batteryTypes.list,
  }), [batteryTypes.list]);

  return (
    <Fragment>
      <Button {...btnMore}>More Settings</Button>
      <Button {...btnAddBatteryType}>Add Battery Type</Button>
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>Battery Types</Typography>
        <Table {...tableProps} />
      </Paper>
      <BatteryTypeModal {...modal} close={() => setModal({type: null, row: null})} />
    </Fragment>
  );
};

export default BatteryTypeTable;
