import React, {Fragment, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, List, ListItem, Typography, ListItemIcon, ListItemText, MenuItem, Paper} from '@material-ui/core';
import {ContactPhone, PhoneInTalk, Print} from '@material-ui/icons';
import {object, string} from 'yup';
import {firestoreUtility, phoneRegex} from 'utilities';
import {Alert, Formik} from '@kbi/component-library';
const {FormikForm, SubmitButton, TextField, SelectField} = Formik;

const ContactShipper = () => {
  const [alert, setAlert] = useState({text: '', in: false, severity: 'warning'});
  const {currentUser} = useSelector(state => state.auth);
  const styles = useStyles();
  const mainTextProps = useMemo(() => ({
    gutterBottom: true,
    style: {
      color: 'black',
      textAlign: 'center',
      width: '100%',
    },
    variant: 'h6',
  }), []);
  const menuSideProps = useMemo(() => ({
    style: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'ghostwhite',
      border: '1px solid darkgray',
      margin: '8px 0px',
    },
    subheader: <Typography variant='subtitle1' style={{marginLeft: '8px', marginTop: '8px'}}>Direct Contact</Typography>,
  }), []);
  const formProps = useMemo(() => ({
    initialValues: {
      Subject: '',
      Name: currentUser?.name || '',
      Phone: '',
      Email: currentUser?.email || '',
      Comment: '',
    },
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const writeDoc = {
            collection: 'Emails',
            writeType: 'add',
            docFields: {
              to: 'hd-support@xevstrategies.com',
              from: currentUser.email,
              replyTo: values.Email,
              template: {
                name: 'ContactForm',
                data: {
                  SUBJECT: values.Subject,
                  NAME: values.Name,
                  PHONE: values.Phone,
                  EMAIL: values.Email,
                  COMMENT: values.Comment,
                },
              },
            },
          };
          await firestoreUtility(writeDoc);
          setAlert({text: 'Contact form was successfully sent.', in: true, severity: 'success'});
          setTimeout(() => actions.setSubmitting(false), 750);
        }
        catch (error) {
          actions.setStatus({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      Subject: string().required(),
      Name: string().required(),
      Phone: string().required().matches(phoneRegex, 'Phone is not properly formatted (e.g. 555-555-5555)'),
      Email: string().required().email('Email is not properly formatted (e.g. name@domain.com)'),
      Comment: string().required(),
    }),
  }), [currentUser]);

  if (!currentUser) return null;
  return (
    <Fragment>
      <Grid spacing={2} container>
        <Grid item xs={12} md={3}>
          <Paper className={styles.paper} elevation={2} style={{flexDirection: 'column', alignItems: 'center'}}>
            <ContactPhone style={{fontSize: '10em', width: '100%'}} color='primary' />
            <Typography {...mainTextProps}>Contact Shipper</Typography>
            <List {...menuSideProps}>
              <ListItem>
                <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
                  <PhoneInTalk />
                </ListItemIcon>
                <ListItemText primary='P: 800-502-9258' />
              </ListItem>
              <ListItem>
                <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
                  <Print />
                </ListItemIcon>
                <ListItemText primary='F: 714-510-2962' />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper className={styles.paper} elevation={2}>
            <Grid spacing={0} container>
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Contact Form
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormikForm {...formProps}>
                  {formikProps => (
                    <Fragment>
                      <Grid spacing={4} container>
                        <Grid item xs={6}>
                          <SelectField name='Subject' required variant='outlined'>
                            {['Schedule Battery Return', 'Technical Support', 'Other'].map(purpose => (
                              <MenuItem key={purpose} value={purpose}>
                                {purpose}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField name='Name' required variant='outlined' />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField name='Phone' required variant='outlined' />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField name='Email' required variant='outlined' />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField name='Comment' required multiline={true} variant='outlined' />
                        </Grid>
                      </Grid>
                      <div style={{marginTop: '24px'}} />
                      <SubmitButton />
                    </Fragment>
                  )}
                </FormikForm>
              </Grid>
              <Grid item xs={12}>
                <Alert text={alert.text} in={alert.in} severity={alert.severity} onClose={() => setAlert({...alert, in: false})} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
export default ContactShipper;
