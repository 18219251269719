import React from 'react'; // eslint-disable-line no-unused-vars
import {SET_AFFILIATE_SITES, SET_BATTERY_RETURNS, SET_BATTERY_TYPES, SET_RECEIVING_SITES, UNSET_COLLECTIONS} from '../types';
import {Firestore} from 'firebaseConfig';

const getAffiliateSitesCollection = ({dispatch}) => {
  const list = [];
  const ref = {};
  const affiliateSitesRef = Firestore.collection('AffiliateSites');
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.BatteryPickupId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), AffiliateSiteId: doc.id};
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          ref[doc.id] = docData;
        }
      }
    }
    dispatch({type: SET_AFFILIATE_SITES, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('Error occured', error);
  };
  return affiliateSitesRef.onSnapshot(options, handleQuerySnap, handleQueryError);
};
const getBatteryReturnsCollection = ({currentUser, dispatch}) => {
  const list = [];
  const ref = {};
  const batteryReturnsRef = (() => {
    if (['Admin', 'Harley', 'KBI'].includes(currentUser.group)) return Firestore.collection('BatteryReturns');
    if (currentUser.group === 'H-D Net') return Firestore.collection('BatteryReturns').where('DealerNo', '==', currentUser.dealerNo);
    if (currentUser.group === 'Affiliate') return Firestore.collection('BatteryReturns').where('AffiliateId', '==', currentUser.affiliateId);
  })();
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.BatteryPickupId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const data = doc.data();
        const docData = {
          ...data,
          BatteryReturnId: doc.id,
          Date: {
            Received: data.Date.Received ? data.Date.Received.toDate() : null,
            Reminder: data.Date.Reminder ? data.Date.Reminder.toDate() : null,
            Requested: data.Date.Requested ? data.Date.Requested.toDate() : null,
          },
          Location: (() => {
            if (data.LocationType === 'Affiliate') return data.AffiliateId;
            if (data.LocationType === 'H-D Net') return data.DealerNo;
          })(),
          ShipmentDetails: !data.ShipmentDetails ? null : {
            ...data.ShipmentDetails,
            PickupDate: data.ShipmentDetails.PickupDate ? data.ShipmentDetails.PickupDate.toDate() : null,
          },
        };
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          ref[doc.id] = docData;
        }
      }
    }
    dispatch({type: SET_BATTERY_RETURNS, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('Error occured', error);
  };
  return batteryReturnsRef.onSnapshot(options, handleQuerySnap, handleQueryError);
};
const getBatteryTypesCollection = ({dispatch}) => {
  const list = [];
  const ref = {};
  const batteryTypesRef = Firestore.collection('BatteryTypes');
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.BatteryPickupId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), BatteryTypeId: doc.id};
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          ref[doc.id] = docData;
        }
      }
    }
    dispatch({type: SET_BATTERY_TYPES, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('Error occured', error);
  };
  return batteryTypesRef.onSnapshot(options, handleQuerySnap, handleQueryError);
};
const getReceivingSitesCollection = ({dispatch}) => {
  const list = [];
  const ref = {};
  const receivingSitesRef = Firestore.collection('ReceivingSites');
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.BatteryPickupId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), ReceivingSiteId: doc.id};
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          ref[doc.id] = docData;
        }
      }
    }
    dispatch({type: SET_RECEIVING_SITES, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('Error occured', error);
  };
  receivingSitesRef.onSnapshot(options, handleQuerySnap, handleQueryError);
};
export const acUnsetCollections = () => {
  return {type: UNSET_COLLECTIONS};
};

// All React View Listeners
export const listenBatteryReturns = ({currentUser, dispatch}) => () => {
  if (currentUser) {
    const affiliateSitesCollectionListener = ['Admin', 'Harley', 'KBI'].includes(currentUser.group) ? getAffiliateSitesCollection({dispatch}) : null;
    const batteryReturnsCollectionListener = getBatteryReturnsCollection({currentUser, dispatch});
    const batteryTypesCollectionListener = getBatteryTypesCollection({dispatch});
    const unsubscribe = () => {
      if (affiliateSitesCollectionListener) affiliateSitesCollectionListener();
      if (batteryReturnsCollectionListener) batteryReturnsCollectionListener();
      if (batteryTypesCollectionListener) batteryTypesCollectionListener();
    };
    return () => unsubscribe();
  }
};
export const listenBatteryReturnDetails = ({currentUser, dispatch}) => () => {
  if (currentUser) {
    const batteryReturnsCollectionListener = getBatteryReturnsCollection({currentUser, dispatch});
    const batteryTypesCollectionListener = getBatteryTypesCollection({dispatch});
    const receivingSitesCollectionListener = ['Admin', 'KBI', 'Harley'].includes(currentUser.group) ? getReceivingSitesCollection({dispatch}) : null;
    getReceivingSitesCollection({dispatch});
    const unsubscribe = () => {
      if (batteryReturnsCollectionListener) batteryReturnsCollectionListener();
      if (batteryTypesCollectionListener) batteryTypesCollectionListener();
      if (receivingSitesCollectionListener) receivingSitesCollectionListener();
    };
    return () => unsubscribe();
  }
};
export const listenAdminSettings = ({currentUser, dispatch}) => () => {
  if (currentUser) {
    const affiliateSitesCollectionListener = getAffiliateSitesCollection({dispatch});
    const batteryTypesCollectionListener = getBatteryTypesCollection({dispatch});
    const receivingSitesCollectionListener = getReceivingSitesCollection({dispatch});
    const unsubscribe = () => {
      if (affiliateSitesCollectionListener) affiliateSitesCollectionListener();
      if (batteryTypesCollectionListener) batteryTypesCollectionListener();
      if (receivingSitesCollectionListener) receivingSitesCollectionListener();
    };
    return () => unsubscribe();
  }
};
