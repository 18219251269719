import React, {Fragment, useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ListItem, ListItemText, Divider, ListItemIcon} from '@material-ui/core';

const MenuItem = (props) => {
  const history = useHistory();
  const {Icon, link, text, visible} = props;

  const doesURLContain = useMemo(() => {
    if (history.location.pathname.indexOf(link) === -1) return false;
    else return true;
  }, [history.location.pathname, link]);
  const handleClick = useCallback(() => {
    if (history.location.pathname !== link) history.push(link);
  }, [history, link]);

  if (!visible) return null;
  return (
    <Fragment>
      <ListItem button selected={doesURLContain} onClick={handleClick} style={{padding: '16px'}}>
        <ListItemIcon>
          <Icon color='secondary' style={{fontSize: '2.2em'}} />
        </ListItemIcon>
        <ListItemText primary={text} primaryTypographyProps={{variant: 'subtitle2', style: {fontSize: '1.1rem', fontWeight: 400}}} />
      </ListItem>
      <Divider />
    </Fragment>
  );
};

MenuItem.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};
export default MenuItem;
