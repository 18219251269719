import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {Grid, MenuItem, Typography} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {Storage} from 'firebaseConfig';
import {Collapse, Formik} from '@kbi/component-library';
const {AutoComplete, SelectField, TextField, VinField} = Formik;

const StageBattery = ({stage}) => {
  const {batteryTypes} = useSelector(state => state.firestore);
  const [labelUrl, setLabelUrl] = useState(null);
  const formik = useFormikContext();

  const partNoProps = useMemo(() => ({
    label: 'Part No.',
    name: 'BatteryPartNo',
    options: batteryTypes.list || [],
    optionKey: 'PartNumber',
    required: true,
    onChange: ({field, form}) => {
      form.setFieldValue('BatteryRate', field.value ? field.value.Rate : 0);
      form.setFieldValue('BatteryName', field.value ? field.value.Name : '');
      form.setFieldValue('BatteryShippedSerialNo', `${field.value.PartNumber}${form.values.BatteryMfgSerialNo.toUpperCase()}${form.values.BatteryJulianDate}`);
    },
  }), [batteryTypes.list]);
  const serialNoProps = useMemo(() => ({
    label: 'Mfg. Serial No.',
    name: 'BatteryMfgSerialNo',
    required: true,
    onBlur: ({form, field}) => {
      form.setFieldValue('BatteryShippedSerialNo', `${form.values.BatteryPartNo}${form.values.BatteryMfgSerialNo.toUpperCase()}${form.values.BatteryJulianDate}`);
      form.setFieldValue('BatteryMfgSerialNo', field.value.toUpperCase());
    },
    onChange: ({event, form}) => {
      if (event.target.value.length > 4) form.setFieldValue('BatteryMfgSerialNo', event.target.value.substring(0, 4));
    },
  }), []);
  const julianDateProps = useMemo(() => ({
    label: 'Julian Date',
    name: 'BatteryJulianDate',
    placeholder: 'YYDDD',
    required: true,
    onBlur: ({form}) => {
      form.setFieldValue('BatteryShippedSerialNo', `${form.values.BatteryPartNo}${form.values.BatteryMfgSerialNo.toUpperCase()}${form.values.BatteryJulianDate}`);
    },
    onKeyDown: event => {
      const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
      const allowedOther = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
      if (event.target.value.length <= 4 && allowedKeys.includes(event.key)) event.persist();
      else if (allowedOther.includes(event.key)) event.persist();
      else event.preventDefault();
    },
  }), []);
  const vinAvailableProps = useMemo(() => ({
    label: 'VIN Available',
    name: 'BatteryVinAvailable',
    required: true,
    onChange: ({event, form}) => {
      if (event.target.value === 'No') {
        form.setFieldValue('BatteryVinNumber', 'Not Required');
        form.setFieldError('BatteryWarranty', false);
      }
      if (event.target.value === 'Yes') {
        form.setFieldValue('BatteryVinNumber', '');
      }
      form.setFieldError('BatteryVinNumber', false);
      form.setFieldTouched('BatteryVinNumber', false);
    },
  }), []);
  const vinNumberProps = useMemo(() => ({
    label: 'VIN Number',
    name: 'BatteryVinNumber',
    required: true,
    disabled: formik.values.BatteryVinAvailable !== 'Yes',
  }), [formik.values.BatteryVinAvailable]);
  const warrantyProps = useMemo(() => ({
    name: 'BatteryWarranty',
    label: 'Warranty Return',
    required: true,
    onChange: ({event, form}) => {
      if (event.target.value === 'No') {
        form.setFieldValue('BatteryWarrantyAuth', 'Not Required');
        form.setFieldError('BatteryWarranty', false);
      }
      if (event.target.value === 'Yes' && form.values.BatteryWarrantyAuth === 'Not Required') {
        form.setFieldValue('BatteryWarrantyAuth', '');
      }
      form.setFieldError('BatteryWarrantyAuth', false);
      form.setFieldTouched('BatteryWarrantyAuth', false);
    },
  }), []);
  const warrantyAuthProps = useMemo(() => ({
    name: 'BatteryWarrantyAuth',
    label: 'Warranty Auth No.',
    required: true,
    onKeyDown: event => {
      const persistKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      if (persistKeys.includes(event.key) && event.target.value.length < 6) event.persist();
      else if (['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'].includes(event.key)) event.persist();
      else event.preventDefault();
    },
  }), []);

  useEffect(() => {
    // useEffect gets the download url for Samsung SDI label.
    const labelRef = Storage.ref('SiteAssets/SDI_Label_66000189.png');
    const getDownloadUrl = async () => setLabelUrl(await labelRef.getDownloadURL());
    getDownloadUrl();
  }, [setLabelUrl]);

  return (
    <Collapse in={stage === 'battery'}>
      <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Battery Information</Typography>
      <Typography variant='subtitle1' style={{marginBottom: '8px'}}>Enter details for the battery to be returned.</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {!labelUrl && <Skeleton variant="rect" width='100%' height='100%' animation='wave' />}
          {labelUrl && <img src={labelUrl} alt='Samsung SDI Label' style={{width: '100%'}} />}
        </Grid>
        <Grid item container spacing={0} xs={12} sm={6}>
          <Grid item xs={12}>
            <AutoComplete {...partNoProps} />
          </Grid>
          <Grid item xs={12}>
            <TextField {...serialNoProps} />
          </Grid>
          <Grid item xs={12}>
            <TextField {...julianDateProps} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField {...vinAvailableProps}>
            {['Yes', 'No'].map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <VinField {...vinNumberProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField {...warrantyProps}>
            {['Yes', 'No'].map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...warrantyAuthProps} disabled={formik.values.BatteryWarranty !== 'Yes'} />
        </Grid>
      </Grid>
    </Collapse>
  );
};

StageBattery.propTypes = {
  stage: PropTypes.string.isRequired,
};
export default StageBattery;
