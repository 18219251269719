import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ModalNewReturn, ViewNoReturns, ViewReturnsTable} from './BatteryReturns/';
import {listenBatteryReturns} from 'state/actions/firestore';
import {AsyncLoader} from 'components';

const BatteryReturns = () => {
  const {currentUser} = useSelector(state => state.auth);
  const {batteryReturns} = useSelector(state => state.firestore);
  const [modal, setModal] = useState({type: null});
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenBatteryReturns({currentUser, dispatch}), [currentUser]);

  if (!currentUser || batteryReturns.list === null) return <AsyncLoader />;
  return (
    <Fragment>
      {batteryReturns.list.length === 0 && <ViewNoReturns setModal={setModal} />}
      {batteryReturns.list.length > 0 && <ViewReturnsTable setModal={setModal} />}
      <ModalNewReturn open={modal.type === 'newBatteryPickup'} close={() => setModal({type: null})} />
    </Fragment>
  );
};

export default BatteryReturns;
