import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {List, Typography} from '@material-ui/core';
import {ViewEmailDocuments, ViewFinalizeAmount, ViewReceiveBattery, ViewSetReminder, ViewUploadDocument, ViewScheduleShipment, ViewEditReturn} from './ViewAdminActions/';

const ViewAdminActions = ({selectedReturn}) => {
  const {currentUser} = useSelector(state => state.auth);
  const fileCount = useMemo(() => {
    const files = selectedReturn?.Files || null;
    if (!files) return 0;
    else return Object.keys(files).length;
  }, [selectedReturn]);
  const menuSideProps = useMemo(() => ({
    style: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'ghostwhite',
      border: '1px solid darkgray',
      margin: '8px 0px',
    },
    subheader: <Typography variant='subtitle1' style={{marginLeft: '8px', marginTop: '8px'}}>Admin Actions</Typography>,
  }), []);
  if (!['Admin', 'KBI'].includes(currentUser.group)) return null;
  return (
    <List {...menuSideProps}>
      <ViewEditReturn selectedReturn={selectedReturn} currentUser={currentUser} />
      <ViewScheduleShipment selectedReturn={selectedReturn} />
      <ViewReceiveBattery selectedReturn={selectedReturn} />
      <ViewFinalizeAmount selectedReturn={selectedReturn} />
      <ViewUploadDocument selectedReturn={selectedReturn} />
      <ViewEmailDocuments selectedReturn={selectedReturn} fileCount={fileCount} />
      <ViewSetReminder selectedReturn={selectedReturn} />
    </List>
  );
};

ViewAdminActions.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewAdminActions;
