import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {Collapse} from '@kbi/component-library';
import {CheckCircleOutline} from '@material-ui/icons';

const StageSuccess = ({stage}) => {
  return (
    <Collapse in={stage === 'success'}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <CheckCircleOutline style={{fontSize: '10em'}} color='primary' />
        <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
          Battery return request has been received.
        </Typography>
        <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
          You will be contacted shortly.
        </Typography>
      </div>
    </Collapse>
  );
};

StageSuccess.propTypes = {
  stage: PropTypes.string.isRequired,
};
export default StageSuccess;
