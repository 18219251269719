import React, {Fragment, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Paper, Typography} from '@material-ui/core';
import {AddBox, Edit, More} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {PortalUserModal} from './index';

const PortalUserTable = (props) => {
  const [modal, setModal] = useState({type: null, row: null});
  const {portalUsers} = useSelector(state => state.auth);

  const btnMore = useMemo(() => ({
    color: 'primary',
    component: Link,
    style: {margin: '16px 0px'},
    startIcon: <More />,
    to: '/portal/admin-settings/',
    variant: 'outlined',
  }), []);
  const btnAddPortalUser = useMemo(() => ({
    color: 'primary',
    startIcon: <AddBox />,
    style: {marginLeft: '16px'},
    variant: 'outlined',
    onClick: () => setModal({type: 'addPortalUser'}),
  }), []);
  const tableProps = useMemo(() => ({
    actionsPerRow: [
      {icon: Edit, tooltip: 'Edit Portal User', onClick: ({event, rowData, rowIndex}) => {
        if (rowData.group === 'H-D Net') alert('Unable to edit H-D Net user.');
        else setModal({type: 'editPortalUser', row: rowData});
      }},
    ],
    columns: [
      {Header: 'Active', accessor: 'active', type: 'boolean'},
      {Header: 'Display Name', accessor: 'displayName'},
      {Header: 'Email', accessor: 'email'},
      {Header: 'Group', accessor: 'group', filterField: 'select'},
      {Header: 'Created On', accessor: 'creationTime', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Last Sign In', accessor: 'lastSignInTime', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
    ],
    data: portalUsers ? portalUsers : [],
    sortInitial: [{id: 'displayName'}],
    isLoading: !portalUsers,
  }), [portalUsers]);

  return (
    <Fragment>
      <Button {...btnMore}>More Settings</Button>
      <Button {...btnAddPortalUser}>Add Portal User</Button>
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>Portal Users</Typography>
        <Table {...tableProps} />
      </Paper>
      <PortalUserModal {...modal} close={() => setModal({type: null, row: null})} />
    </Fragment>
  );
};

export default PortalUserTable;
