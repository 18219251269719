import React, {Fragment, useCallback, useState} from 'react';
import {ListItem, ListItemText, Divider, ListItemIcon} from '@material-ui/core';
import {PowerSettingsNew} from '@material-ui/icons';
import {Redirect} from 'react-router-dom';
import {Auth} from 'firebaseConfig.js';

const LogoutItem = (props) => {
  const [redirect, setRedirect] = useState(null);
  const handleLogout = useCallback(() => {
    setRedirect('/signin');
    setTimeout(() => Auth.signOut(), 500);
  }, []);

  if (redirect) return <Redirect to={redirect} />;
  return (
    <Fragment>
      <ListItem button onClick={handleLogout} style={{padding: '16px'}}>
        <ListItemIcon>
          <PowerSettingsNew color='secondary' style={{fontSize: '2.2em'}} />
        </ListItemIcon>
        <ListItemText primary='Logout' primaryTypographyProps={{variant: 'subtitle2', style: {fontSize: '1.1rem', fontWeight: 400}}} />
      </ListItem>
      <Divider />
    </Fragment>
  );
};

export default React.memo(LogoutItem);
