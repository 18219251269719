import {SET_CURRENT_USER, UNSET_CURRENT_USER, SET_PORTAL_USERS} from 'state/types';
import {Functions} from 'firebaseConfig';
import moment from 'moment';

export const acSetCurrentUser = payload => {
  return {type: SET_CURRENT_USER, payload};
};
export const acUnsetCurrentUser = () => {
  return {type: UNSET_CURRENT_USER};
};
export const acGetPortalUsersList = ({currentUser, dispatch}) => () => {
  const getUsers = async () => {
    try {
      const result = await Functions.httpsCallable('getUsers')();
      const payload = result.data.map(user => {
        const userDetails = {
          active: user.active,
          displayName: user.displayName,
          email: user.email,
          group: user.group,
          uid: user.uid,
          creationTime: moment(user.metadata.creationTime).isValid() ? moment(user.metadata.creationTime).toDate() : 'N/A',
          lastSignInTime: moment(user.metadata.lastSignInTime).isValid() ? moment(user.metadata.lastSignInTime).toDate() : 'N/A',
        };
        if (userDetails.group === 'Affiliate') userDetails.affiliateId = user.affiliateId;
        return userDetails;
      });
      dispatch({type: SET_PORTAL_USERS, payload});
    }
    catch (error) {
      console.log('getPortalUsersList Error', error);
    }
  };
  if (currentUser) getUsers();
};
export const acAddPortalUser = async ({affiliateId, dispatch, displayName, email, group, password, portalUsers}) => {
  try {
    const result = await Functions.httpsCallable('createUser')({affiliateId, displayName, email, group, password});
    const {customClaims, disabled, metadata, ...otherData} = result.data;
    const newUser = {
      active: !disabled,
      group: customClaims.group,
      creationTime: moment(metadata.creationTime).isValid() ? moment(metadata.creationTime).toDate() : 'N/A',
      lastSignInTime: moment(metadata.lastSignInTime).isValid() ? moment(metadata.lastSignInTime).toDate() : 'N/A',
      ...otherData,
    };
    if (newUser.group === 'Affiliate') newUser.affiliateId = customClaims.affiliateId;
    dispatch({type: SET_PORTAL_USERS, payload: [...portalUsers, newUser]});
  }
  catch (error) {
    throw new Error(error.message);
  }
};
export const acUpdatePortalUser = async ({active, affiliateId, dispatch, displayName, email, group, portalUsers, uid}) => {
  try {
    const result = await Functions.httpsCallable('updateUser')({active, affiliateId, displayName, email, group, uid});
    const {customClaims, disabled, metadata, ...otherData} = result.data;
    const updatedUser = {
      active: !disabled,
      group: customClaims.group,
      creationTime: moment(metadata.creationTime).isValid() ? moment(metadata.creationTime).toDate() : 'N/A',
      lastSignInTime: moment(metadata.lastSignInTime).isValid() ? moment(metadata.lastSignInTime).toDate() : 'N/A',
      ...otherData,
    };
    const duplicateUserIndex = portalUsers.findIndex(user => {
      return user.uid === updatedUser.uid;
    });
    portalUsers.splice(duplicateUserIndex, 1, updatedUser);
    dispatch({type: SET_PORTAL_USERS, payload: [...portalUsers]});
  }
  catch (error) {
    throw new Error(error.message);
  }
};
