import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Button, Collapse, Grid, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {boolean, number, object, string} from 'yup';
import {firestoreUtility} from 'utilities';
import {Alert, Formik} from '@kbi/component-library';
const {CurrencyField, FormikForm, SelectField, TextField, WeightField} = Formik;

const BatteryTypeModal = ({close, row, type}) => {
  const [alert, setAlert] = useState({text: '', in: false, severity: 'warning'});
  const [stage, setStage] = useState('enterBatteryType');
  const handleClose = useCallback(() => {
    setStage('enterBatteryType');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open: type === 'addBatteryType' || type === 'editBatteryType',
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [type]);
  const formProps = useMemo(() => ({
    initialValues: (() => {
      const isEdit = type === 'editBatteryType';
      return {
        Active: isEdit && row ? row.Active : true,
        Weight: (() => {
          const Weight = isEdit && row ? row.Weight : '';
          const WeightFixed = parseFloat(Weight).toFixed(2);
          if (!isNaN(WeightFixed)) return WeightFixed;
          else return '';
        })(),
        Dimensions: isEdit && row ? row.Dimensions : '',
        Name: isEdit && row ? row.Name : '',
        Note: isEdit && row ? row.Note : '',
        PartNumber: isEdit && row ? row.PartNumber : '',
        Rate: (() => {
          const Rate = isEdit && row ? row.Rate : '';
          const RateFixed = parseFloat(Rate).toFixed(2);
          if (!isNaN(RateFixed)) return RateFixed;
          else return '';
        })(),
      };
    })(),
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const updateDoc = {
            collection: 'BatteryTypes',
            writeType: (() => {
              if (type === 'addBatteryType') return 'add';
              else if (type === 'editBatteryType') return 'update';
              else return null;
            })(),
            docId: (() => {
              if (type === 'editBatteryType') return row.BatteryTypeId;
              else return null;
            })(),
            docFields: {
              Active: values.Active,
              Dimensions: values.Dimensions.trim(),
              Name: values.Name.trim(),
              Note: values.Note.trim(),
              PartNumber: values.PartNumber.trim(),
              Rate: Number(values.Rate),
              Weight: Number(values.Weight),
            },
          };
          await firestoreUtility(updateDoc);
          setStage('success');
        }
        catch (error) {
          setAlert({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      Active: boolean().required(),
      Dimensions: string().required().min(3),
      Name: string().required().min(3),
      PartNumber: string().label('Part Number').required(),
      Weight: number().required().positive(),
      Rate: number().required(),
    }),
  }), [row, type]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({isSubmitting, isValidating}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                {type === 'addBatteryType' ? 'Create New' : 'Update'} Battery Type
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'enterBatteryType'} timeout={{enter: 500, exit: 500}}>
                <DialogContentText>Enter battery type details below:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectField name="Active" disabled={Boolean(type === 'addBatteryType')} required>
                      <MenuItem key={true} value={true}>
                        True
                      </MenuItem>
                      <MenuItem key={false} value={false}>
                        False
                      </MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="Name" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="PartNumber" label="Part Number" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="Dimensions" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <WeightField name="Weight" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField name="Rate" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name="Note" label="Battery Type Note" multiline />
                  </Grid>
                </Grid>
                <Alert text={alert.text} in={alert.in} severity={alert.severity} onClose={() => setAlert({...alert, in: false})} />
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    Battery type successfully {type === 'addBatteryType' ? 'added.' : 'updated.'}
                  </Typography>
                </div>
              </Collapse>
            </DialogContent>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={isSubmitting || isValidating}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting || isValidating}>
                  Submit
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

BatteryTypeModal.propTypes = {
  row: PropTypes.object,
  type: PropTypes.string,
  close: PropTypes.func.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (!prevProps.type && (nextProps.type === 'addBatteryType' || nextProps.type === 'editBatteryType')) return false;
  else if ((prevProps.type === 'addBatteryType' || prevProps.type === 'editBatteryType') && !nextProps.type) return false;
  return true;
};
export default React.memo(BatteryTypeModal, isMemoEqual);
