import React, {Fragment, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Alarm} from '@material-ui/icons';
import moment from 'moment';
import ModalSetReminder from './ModalSetReminder';


const ViewSetReminder = ({selectedReturn}) => {
  const [modal, setModal] = useState(false);
  const reminderProps = useMemo(() => ({
    primary: selectedReturn?.Date?.Reminder ? 'Edit Reminder' : 'Set Reminder',
    secondary: selectedReturn?.Date?.Reminder ? moment(selectedReturn.Date.Reminder).format('MM/DD/YYYY') : null,
  }), [selectedReturn]);

  return (
    <Fragment>
      <ListItem button onClick={() => setModal(true)} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <Alarm />
        </ListItemIcon>
        <ListItemText {...reminderProps} />
      </ListItem>
      <ModalSetReminder open={modal} close={() => setModal(false)} selectedReturn={selectedReturn} />
    </Fragment>
  );
};

ViewSetReminder.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewSetReminder;
