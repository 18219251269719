import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {MenuItem, Grid, Typography, DialogContentText} from '@material-ui/core';
import {countries, provinces, states} from 'constants.js';
import {Formik} from '@kbi/component-library';
const {AutoComplete, CheckboxGroup, TextField, SelectField, VinField} = Formik;

const EditPickupRequest = ({formik}) => {
  const {batteryTypes} = useSelector(state => state.firestore);
  const countryProps = useMemo(() => {
    return {
      name: 'PickupCountry',
      label: 'Country',
      options: countries,
      optionKey: 'value',
      required: true,
    };
  }, []);
  const partNoProps = useMemo(() => ({
    label: 'Part No.',
    name: 'BatteryPartNo',
    options: batteryTypes.list || [],
    optionKey: 'PartNumber',
    required: true,
    onChange: ({field, form}) => {
      form.setFieldValue('BatteryName', field.value ? field.value.Name : '');
      form.setFieldValue('BatteryShippedSerialNo', `${field.value.PartNumber}${form.values.BatteryMfgSerialNo.toUpperCase()}${form.values.BatteryJulianDate}`);
    },
  }), [batteryTypes.list]);
  const serialNoProps = useMemo(() => ({
    label: 'Mfg. Serial No.',
    name: 'BatteryMfgSerialNo',
    required: true,
    onBlur: ({form, field}) => {
      form.setFieldValue('BatteryShippedSerialNo', `${form.values.BatteryPartNo}${form.values.BatteryMfgSerialNo.toUpperCase()}${form.values.BatteryJulianDate}`);
      form.setFieldValue('BatteryMfgSerialNo', field.value.toUpperCase());
    },
    onChange: ({event, form}) => {
      if (event.target.value.length > 4) form.setFieldValue('BatteryMfgSerialNo', event.target.value.substring(0, 4));
    },
  }), []);
  const julianDateProps = useMemo(() => ({
    label: 'Julian Date',
    name: 'BatteryJulianDate',
    placeholder: 'YYDDD',
    required: true,
    onBlur: ({form}) => {
      form.setFieldValue('BatteryShippedSerialNo', `${form.values.BatteryPartNo}${form.values.BatteryMfgSerialNo.toUpperCase()}${form.values.BatteryJulianDate}`);
    },
    onKeyDown: event => {
      const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
      const allowedOther = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
      if (event.target.value.length <= 4 && allowedKeys.includes(event.key)) event.persist();
      else if (allowedOther.includes(event.key)) event.persist();
      else event.preventDefault();
    },
  }), []);
  const vinAvailableProps = useMemo(() => ({
    label: 'VIN Available',
    name: 'BatteryVinAvailable',
    required: true,
    onChange: ({event, form}) => {
      if (event.target.value === 'No') {
        form.setFieldValue('BatteryVinNumber', 'Not Required');
        form.setFieldError('BatteryWarranty', false);
      }
      if (event.target.value === 'Yes') {
        form.setFieldValue('BatteryVinNumber', '');
      }
      form.setFieldError('BatteryVinNumber', false);
      form.setFieldTouched('BatteryVinNumber', false);
    },
  }), []);
  const vinNumberProps = useMemo(() => ({
    label: 'VIN Number',
    name: 'BatteryVinNumber',
    required: true,
    disabled: formik.values.BatteryVinAvailable !== 'Yes',
  }), [formik.values.BatteryVinAvailable]);
  const warrantyProps = useMemo(() => ({
    name: 'BatteryWarranty',
    label: 'Warranty Return',
    required: true,
    onChange: ({event, form}) => {
      if (event.target.value === 'No') {
        form.setFieldValue('BatteryWarrantyAuth', 'Not Required');
        form.setFieldError('BatteryWarranty', false);
      }
      if (event.target.value === 'Yes' && form.values.BatteryWarrantyAuth === 'Not Required') {
        form.setFieldValue('BatteryWarrantyAuth', '');
      }
      form.setFieldError('BatteryWarrantyAuth', false);
      form.setFieldTouched('BatteryWarrantyAuth', false);
    },
  }), []);
  const warrantyAuthProps = useMemo(() => ({
    name: 'BatteryWarrantyAuth',
    label: 'Warranty Auth No.',
    required: true,
    onKeyDown: event => {
      const persistKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      if (persistKeys.includes(event.key) && event.target.value.length < 6) event.persist();
      else if (['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'].includes(event.key)) event.persist();
      else event.preventDefault();
    },
  }), []);
  const hazardProps = useMemo(() => {
    const batteryHazards = [
      {
        label: 'Warm to the touch (i.e. above room temperature).',
        value: 'Warm to the touch (i.e. above room temperature).',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Signs of heat damage, such as burn marks or warping.',
        value: 'Signs of heat damage, such as burn marks or warping.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Exposed/unprotected wires capable of causing an electric shock.',
        value: 'Exposed/unprotected wires capable of causing an electric shock.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Signs of damage, such as dented, breached, or swollen components.',
        value: 'Signs of damage, such as dented, breached, or swollen components.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'Any other indicator that it may not be safe to transport as-is.',
        value: 'Any other indicator that it may not be safe to transport as-is.',
        CheckboxProps: {color: 'secondary'},
      },
      {
        label: 'None of the above.',
        value: 'None of the above.',
      },
    ];
    return {
      boxes: batteryHazards,
      label: ' ',
      name: 'BatteryHazards',
      onChange: ({event, form, field, meta}) => {
        const {value} = event.target;
        const noneSelected = value === 'None of the above.';
        let formHazards = form.values.BatteryHazards;
        if (formHazards.includes(value) && !noneSelected) formHazards = formHazards.filter(hazard => value !== hazard && value !== 'None of the above.');
        else if (!formHazards.includes(value) && noneSelected) formHazards = ['None of the above.'];
        else if (formHazards.includes(value) && noneSelected) formHazards = [];
        else if (!formHazards.includes(value) && !noneSelected) {
          formHazards.push(event.target.value);
          formHazards = formHazards.filter(hazard => hazard !== 'None of the above.');
        }
        form.setFieldValue('BatteryHazards', formHazards);
      },
    };
  }, []);

  return (
    <>
      <DialogContentText>Pickup Details</DialogContentText>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupAddress1' label='Pickup Address 1' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupAddress2' label='Pickup Address 2' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoComplete {...countryProps} onChange={(stuff) => formik.setFieldValue('PickupStateProvince', '')} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoStateProvince country={formik.values.PickupCountry} locationFound={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupCity' label='City' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='PickupPostalCode' label='Postal Code' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactName' label='Name' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactEmail' label='Email' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactPhone' label='Phone' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactFax' label='Fax' />
        </Grid>
        <Grid item xs={12}>
          <AutoComplete {...partNoProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...serialNoProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...julianDateProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField {...vinAvailableProps}>
            {['Yes', 'No'].map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <VinField {...vinNumberProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField {...warrantyProps}>
            {['Yes', 'No'].map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...warrantyAuthProps} disabled={formik.values.BatteryWarranty !== 'Yes'} />
        </Grid>
        <hr style={{width: '100%'}} />
        <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem', display: 'block', width: '100%'}}>Battery Hazards</Typography>
        <Typography variant='body1' style={{lineHeight: 1.1, marginBottom: '16px'}}>Check whether any of the following apply to the battery.</Typography>
        <Grid item xs={12}>
          <CheckboxGroup {...hazardProps} />
        </Grid>
      </Grid>
    </>
  );
};

EditPickupRequest.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default EditPickupRequest;


const AutoStateProvince = ({country, locationFound}) => {
  if (country === 'Canada') {
    return (
      <AutoComplete name='PickupStateProvince' label='Province' options={provinces} optionKey='value' required disabled={!locationFound} />
    );
  }
  return (
    <AutoComplete name='PickupStateProvince' label='State' options={states} optionKey='value' required disabled={!locationFound} />
  );
};
AutoStateProvince.propTypes = {
  country: PropTypes.string.isRequired,
  locationFound: PropTypes.bool.isRequired,
};
