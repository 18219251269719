import {SET_CURRENT_USER, UNSET_CURRENT_USER, SET_PORTAL_USERS} from 'state/types';

const INITIAL_STATE = {
  currentUser: null,
  portalUsers: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_CURRENT_USER:
    return {...state, currentUser: payload};
  case UNSET_CURRENT_USER:
    return {...state, currentUser: null};
  case SET_PORTAL_USERS:
    return {...state, portalUsers: payload};
  default:
    return state;
  }
};
