import {SET_AFFILIATE_SITES, SET_BATTERY_RETURNS, SET_BATTERY_TYPES, SET_RECEIVING_SITES, UNSET_COLLECTIONS} from '../types';

const INITIAL_STATE = {
  affiliateSites: {list: null, ref: null},
  batteryReturns: {list: null, ref: null},
  batteryTypes: {list: null, ref: null},
  receivingSites: {list: null, ref: null},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_AFFILIATE_SITES:
    return {...state, affiliateSites: payload};
  case SET_BATTERY_RETURNS:
    return {...state, batteryReturns: payload};
  case SET_BATTERY_TYPES:
    return {...state, batteryTypes: payload};
  case SET_RECEIVING_SITES:
    return {...state, receivingSites: payload};
  case UNSET_COLLECTIONS:
    return {...INITIAL_STATE};
  default:
    return state;
  }
};
