import React, {useEffect, useState} from 'react';
import {Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {acSetCurrentUser, acUnsetCurrentUser} from '../state/actions/auth';
import {acUnsetCollections} from '../state/actions/firestore';
import {Auth} from 'firebaseConfig.js';
import Loader from './Loader';

const PrivateRoute = props => {
  const [auth, setAuth] = useState({checked: false, user: null});
  const dispatch = useDispatch();

  const authListener = () => {
    const getPowerUser = (claims) => ({
      authTime: claims.auth_time,
      email: claims.email,
      expTime: claims.exp,
      group: claims.group,
      name: claims.name,
      uid: claims.user_id,
    });
    const getHDNetUser = (claims) => ({
      authTime: claims.auth_time,
      countryCode: claims.countryCode,
      dealerNo: claims.dealerNo,
      email: claims.email,
      expTime: claims.exp,
      firstName: claims.firstName,
      group: claims.group,
      hdNetUserType: claims.hdNetUserType,
      lastName: claims.lastName,
      name: claims.name,
      preferredLanguage: claims.preferredLanguage,
      uid: claims.user_id,
    });
    const getAffiliateUser = (claims) => ({
      affiliateId: claims.affiliateId,
      authTime: claims.auth_time,
      email: claims.email,
      expTime: claims.exp,
      group: claims.group,
      name: claims.name,
      uid: claims.user_id,
    });
    const unsubscribe = Auth.onIdTokenChanged(async user => {
      if (user && (new Date().getTime() - new Date(user.metadata.lastSignInTime).getTime() < 28000000)) { // If a user logged in more than 8 hours ago (28000000ms)
        let userClaims = null;
        const {claims} = await Auth.currentUser.getIdTokenResult();
        if (['Admin', 'Harley', 'KBI'].includes(claims.group)) userClaims = getPowerUser(claims);
        if (claims.group === 'H-D Net') userClaims = getHDNetUser(claims);
        if (claims.group === 'Affiliate') userClaims = getAffiliateUser(claims);
        setAuth({checked: true, user});
        dispatch(acSetCurrentUser(userClaims));
      }
      else {
        setAuth({checked: true, user: null});
        dispatch(acUnsetCurrentUser());
        dispatch(acUnsetCollections());
      }
    });
    return () => {
      unsubscribe();
      setAuth({checked: true, user: null});
      dispatch(acUnsetCurrentUser());
      dispatch(acUnsetCollections());
    };
  };
  useEffect(authListener, [dispatch]);

  if (auth.checked && auth.user) {
    const {Component, path} = props;
    return <Route path={path} render={props => <Component {...props} currentUser={auth.user} />} />;
  }
  else if (auth.checked && !auth.user) {
    return <Redirect to={'/signin'} />;
  }
  else {
    return <Loader />;
  }
};

PrivateRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]),
  path: PropTypes.string,
};
export default PrivateRoute;
