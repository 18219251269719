import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Grid, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {object, string} from 'yup';
import {firestoreUtility} from 'utilities';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {FormButton, FormikForm, SelectField, SubmitButton, TextField} = Formik;

const ModalEmailDocuments = ({close, open, selectedReturn}) => {
  const [stage, setStage] = useState('sendEmail');
  const {currentUser} = useSelector(state => state.auth);
  const handleClose = useCallback(() => {
    setStage('sendEmail');
    close();
  }, [close]);

  const files = useMemo(() => ({
    Shipping_Document: selectedReturn.Files.Shipping_Document || null,
    Hazmat_Marking: selectedReturn.Files.Hazmat_Marking || null,
    Shipping_Label: selectedReturn.Files.Shipping_Label || null,
  }), [selectedReturn]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {
      CopySender: 'Yes',
      SendTo: selectedReturn?.Contact?.Email || '',
      SendSD: 'Include',
      SendHM: 'Include',
      SendSL: 'Include',
    },
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const addDoc = {
            collection: 'Emails',
            writeType: 'add',
            docFields: {
              to: values.SendTo,
              cc: values.CopySender === 'Yes' ? currentUser.email : '',
              template: {
                data: {
                  BatteryDetailURL: window.location.href,
                  VIN: selectedReturn?.BatteryVin || '',
                  BOL: values.SendSD === 'Include' && Boolean(selectedReturn.Files.Shipping_Document) ? selectedReturn.Files.Shipping_Document : '',
                  HazmatMarking: values.SendHM === 'Include' && Boolean(selectedReturn.Files.Hazmat_Marking) ? selectedReturn.Files.Hazmat_Marking : '',
                  ShippingLabel: values.SendSL === 'Include' && Boolean(selectedReturn.Files.Shipping_Label) ? selectedReturn.Files.Shipping_Label : '',
                },
                name: 'SendFiles',
              },
            },
          };
          await firestoreUtility(addDoc);
          setStage('success');
          setTimeout(() => actions.setSubmitting(false), 750);
        }
        catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      CopySender: string().required().label('Copy Sender'),
      SendTo: string().required().label('Send To'),
      SendSD: string().required().label('Send BOL'),
      SendHM: string().required().label('Send HM'),
      SendSL: string().required().label('Send SL'),
    }),
  }), [currentUser.email, selectedReturn]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({setStatus, status, isSubmitting, isValidating, setFieldValue}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'}>Email Documents</Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'sendEmail'}>
                <DialogContentText>Select documents to include and click send button.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm>
                    <TextField name='SendTo' label='Send To' required />
                  </Grid>
                  <Grid item xs={12} sm>
                    <SelectField name='CopySender' label='Copy Sender' required>
                      {['Yes', 'No'].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {files.Shipping_Document && <Grid item xs={12} sm>
                    <SelectField name='SendSD' label='Send Shipping Document' required>
                      {['Include', 'Exclude'].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>}
                  {files.Hazmat_Marking && <Grid item xs={12} sm>
                    <SelectField name='SendHM' label='Send Hazmat Marking' required>
                      {['Include', 'Exclude'].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>}
                  {files.Shipping_Label && <Grid item xs={12} sm>
                    <SelectField name='SendSL' label='Send Shipping Label' required>
                      {['Include', 'Exclude'].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>}
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    Email has been successfully sent.
                  </Typography>
                </div>
              </Collapse>
              <Alert text={status.text} in={status.in} severity={status.severity} onClose={() => setStatus({...status, in: false})} />
            </DialogContent>
            <Collapse in={stage === 'sendEmail'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='secondary' variant='text'>Cancel</FormButton>
                <SubmitButton type='submit' color='primary' variant='text'>Send</SubmitButton>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <FormButton onClick={() => handleClose()} color='primary' variant='text'>Close</FormButton>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalEmailDocuments.propTypes = {
  close: PropTypes.func.isRequired,
  selectedReturn: PropTypes.object.isRequired,
  open: PropTypes.bool,
};
export default ModalEmailDocuments;
