import {Firestore} from 'firebaseConfig';
import includes from 'lodash.includes';

const firestoreWrite = (utilitySettings) => {
  const errNotice = 'firestoreUtility error:';
  if (!utilitySettings) throw new Error(`${errNotice} settings object parameter {collection, writeType, docFields, docId} required`);
  const {collection, docFields, docId, writeType} = utilitySettings;

  if (!collection) throw new Error(`${errNotice} collection property [e.g. 'BatteryReturns'] is required`);
  if (!docFields) throw new Error(`${errNotice} docFields property is required`);
  if (!writeType || !includes(['add', 'set', 'update'], writeType)) {
    throw new Error(`${errNotice} writeType property ['add', 'set', 'update'] is required`);
  }

  const collectionRef = Firestore.collection(collection);
  if (writeType === 'add') return collectionRef.add(docFields);
  else if (writeType === 'set' && !docId) throw new Error(`${errNotice} docId property required for 'set' writeType`);
  else if (writeType === 'set' && docId) return collectionRef.doc(docId).set(docFields);
  else if (writeType === 'update' && !docId) throw new Error(`${errNotice} docId property required for 'update' writeType`);
  else if (writeType === 'update' && docId) return collectionRef.doc(docId).update(docFields);
};
export const firestoreUtility = async utilitySettings => {
  try {
    const response = await firestoreWrite(utilitySettings);
    return response || null;
  }
  catch (error) {
    throw new Error(error.message);
  }
};

// Resusable Regex
export const postalRegex = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/im;
export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line no-useless-escape
export const julianDateRegex = /^([0-9]{2})(00[1-9]|0[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9]|36[0-6])$/im;
