import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Button, Grid, Paper, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Cancel, LocalShipping, Print} from '@material-ui/icons';
import moment from 'moment';

const PrintDetails = ({selectedReturn}) => {
  const styles = useStyles();
  const cancelButtonProps = useMemo(() => ({
    className: 'HidePrint',
    component: Link,
    size: 'small',
    style: {marginLeft: '8px'},
    to: `/portal/battery-returns/details/${selectedReturn.BatteryReturnId}`,
    variant: 'outlined',
  }), [selectedReturn.BatteryReturnId]);
  const fieldButtonProps = useMemo(() => ({
    className: 'HidePrint',
    size: 'small',
    style: {marginLeft: '0px'},
    variant: 'outlined',
    onClick: () => window.print(),
  }), []);
  const core = useMemo(() => ({
    fieldDate: (name, value) => ({
      variant: 'outlined',
      fullWidth: true,
      InputProps: {readOnly: true},
      label: name,
      margin: 'dense',
      value: value ? moment(value).format('MM/DD/YYYY') : 'Pending',
    }),
    fieldText: (name, value) => ({
      variant: 'outlined',
      fullWidth: true,
      InputLabelProps: {shrink: true},
      InputProps: {readOnly: true},
      label: name,
      margin: 'dense',
      value: value ? value : '',
    }),
  }), []);

  return (
    <Fragment>
      <div style={{margin: '24px 0px'}}>
        <Button {...fieldButtonProps}>
          <Print className={styles.extendedIcon} />Start Print
        </Button>
        <Button {...cancelButtonProps}>
          <Cancel className={styles.extendedIcon} />Cancel Print
        </Button>
      </div>
      <Paper className={styles.paper} elevation={2} id='RemoveElevation'>
        <Grid spacing={2} container style={{margin: 0, width: '100%'}} className='RemoveMinHeight'>
          <Grid item xs={12}>
            <LocalShipping style={{fontSize: '6em', width: '100%'}} color='primary' />
            <Typography variant='h5' style={{width: '100%', marginBottom: '24px', textAlign: 'center'}}>
              Battery Returns Details
            </Typography>
            <Typography variant='h6' gutterBottom style={{width: '100%'}}>
              Return Overview
            </Typography>
            <Grid spacing={2} container style={{marginBottom: '24px'}}>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Return Status', selectedReturn.Status)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldDate('Date Return Requested', selectedReturn.Date.Requested)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldDate('Date Battery Received', selectedReturn.Date.Received)} />
              </Grid>
            </Grid>
            <Typography variant='h6' gutterBottom style={{width: '100%'}}>
              Pickup Information
            </Typography>
            <Grid spacing={2} container style={{marginBottom: '24px'}}>
              <Grid item xs={4}>
                {selectedReturn.LocationType === 'H-D Net' && <TextField {...core.fieldText('Dealer Name (No.)', `${selectedReturn.DealerName} (${selectedReturn.DealerNo})`)} />}
                {selectedReturn.LocationType === 'Affiliate' && <TextField {...core.fieldText('Affiliate Name', `${selectedReturn.AffiliateName}`)} />}
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Address 1', selectedReturn.PickupLocation.Address1)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Address 2', selectedReturn.PickupLocation.Address2)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('City', selectedReturn.PickupLocation.City)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText(selectedReturn.PickupLocation.Country === 'Canada' ? 'Province' : 'State', selectedReturn.PickupLocation.StateProvince)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Postal Code', selectedReturn.PickupLocation.PostalCode)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Country', selectedReturn.PickupLocation.Country)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Shipping Contact', selectedReturn.Contact.Name)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Shipping Phone', selectedReturn.Contact.Phone)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Shipping Fax', selectedReturn.Contact.Fax)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Shipping Email', selectedReturn.Contact.Email)} />
              </Grid>
            </Grid>
            <Typography variant='h6' gutterBottom style={{width: '100%'}}>
              Battery Information
            </Typography>
            <Grid spacing={2} container style={{marginBottom: '24px'}}>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Battery Name', selectedReturn.Battery.Name)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Part No.', selectedReturn.Battery.PartNo)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Serial No.', selectedReturn.Battery.SerialNoShipped)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldText('VIN', selectedReturn.BatteryVin)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Warranty Return', selectedReturn.Battery.Warranty)} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.fieldText('Warranty Auth No.', selectedReturn.Battery.WarrantyAuth)} />
              </Grid>
            </Grid>
            {selectedReturn.ShipmentDetails && <Fragment>
              <Typography variant='h6' gutterBottom style={{width: '100%'}}>
                Return Shipment Information
              </Typography>
              <Grid spacing={2} container style={{marginBottom: '24px'}}>
                <Grid item xs={4}>
                  <TextField {...core.fieldText('Carrier', selectedReturn.ShipmentDetails.Carrier)} />
                </Grid>
                <Grid item xs={4}>
                  <TextField {...core.fieldDate('Pickup Date', selectedReturn.ShipmentDetails.PickupDate)} />
                </Grid>
                <Grid item xs={4}>
                  <TextField {...core.fieldText('Shipping Paper No.', selectedReturn.ShipmentDetails.ShippingPaper)} />
                </Grid>
              </Grid>
            </Fragment>}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: '8px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginTop: '0px',
    overflowX: 'hidden',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '850px',
  },
}));
PrintDetails.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default PrintDetails;
