import React, {useState} from 'react';
import {Drawer, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Switch, Route, Redirect} from 'react-router-dom';
import classNames from 'classnames';
import {MainAppBar, MenuList} from './Portal/index';
import {AdminSettings, BatteryReturns, BatteryReturnDetails, ContactShipper, AdminReports} from './index';

const Portal = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const styles = useStyles();

  const core = {
    drawerMobile: {
      variant: 'temporary',
      anchor: 'left',
      open: drawerOpen,
      onClose: () => setDrawerOpen(!drawerOpen),
      classes: {paper: styles.drawerPaper},
      ModalProps: {keepMounted: true},
    },
    drawerSmall: {
      variant: 'permanent',
      classes: {paper: classNames(styles.drawerPaper, !drawerOpen && styles.drawerPaperClose)},
      open: true,
    },
    drawerLarge: {
      variant: 'permanent',
      classes: {paper: styles.drawerPaper},
    },
    mainAppBar: {
      disableGutters: true,
      toggleDrawer: () => setDrawerOpen(!drawerOpen),
    },
  };
  return (
    <div className={styles.root}>
      <div id='MainMenu'>
        <Hidden smUp>
          <MainAppBar {...core.mainAppBar} />
          <Drawer {...core.drawerMobile} style={{height: '100%'}}>
            <MenuList />
          </Drawer>
        </Hidden>
        <Hidden xsDown lgUp>
          <MainAppBar {...core.mainAppBar} />
          <Drawer {...core.drawerSmall} style={{height: '100%'}}>
            <MenuList />
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <MainAppBar />
          <Drawer {...core.drawerLarge} style={{height: '100%'}}>
            <MenuList />
          </Drawer>
        </Hidden>
      </div>
      <div className={styles.content} id='MainBody'>
        <div className={styles.toolbar} id='Toolbar' />
        <Switch>
          <Route exact path='/portal/admin-settings/:subview' component={AdminSettings} />
          <Route exact path='/portal/admin-settings' component={AdminSettings} />
          <Route exact path='/portal/battery-returns/details/:BatteryReturnId/' component={BatteryReturnDetails} />
          <Route exact path='/portal/battery-returns/details/:BatteryReturnId/:PrintView' component={BatteryReturnDetails} />
          <Route exact path='/portal/battery-returns' component={BatteryReturns} />
          <Route exact path='/portal/reports' component={AdminReports} />
          <Route exact path='/portal/contact-shipper' component={ContactShipper} />
          <Route exact path='/portal/error' component={BatteryReturns} />
          <Redirect push to='/portal/battery-returns' />
        </Switch>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: '#f4f4f4',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    minWidth: 0,
  },
  drawerPaper: {
    [theme.breakpoints.down('xs')]: {
      width: '75vw',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: 240,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      width: 240,
    },
    minHeight: '100vh',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
    },
  },
}));
export default Portal;
