import React, {Fragment, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Paper, Typography} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import moment from 'moment';
import ModalAddAdminNote from './ModalAddAdminNote';

const ViewAdminNotes = ({selectedReturn}) => {
  const {currentUser} = useSelector(state => state.auth);
  const [modal, setModal] = useState('');
  const tableProps = useMemo(() => ({
    actionsBar: [
      {icon: Add, text: 'Add Note', onClick: () => setModal('modalAddNote')},
    ],
    columns: [
      {Header: 'Note', accessor: 'Note'},
      {Header: 'Created By', accessor: 'CreatedBy'},
      {Header: 'Created On', accessor: 'CreatedOn'},
    ],
    data: selectedReturn.Notes.map(note => ({
      Note: note.Note,
      CreatedBy: note.CreatedBy,
      CreatedOn: moment(note.CreatedOn.toDate()).format('MM/DD/YYYY HH:mm:ss'),
    })),
    sortInitial: [{id: 'CreatedOn', desc: true}],
    isLoading: !selectedReturn.Notes,
  }), [selectedReturn.Notes]);

  if (!['Admin', 'KBI'].includes(currentUser.group)) return null;
  return (
    <Fragment>
      <div style={{height: '24px'}} />
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>Admin Notes</Typography>
        <Table {...tableProps} />
      </Paper>
      <ModalAddAdminNote close={() => setModal('')} selectedReturn={selectedReturn} open={modal === 'modalAddNote'} />
    </Fragment>
  );
};

ViewAdminNotes.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewAdminNotes;
