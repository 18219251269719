import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';

const ViewShipmentInfo = ({selectedReturn}) => {
  const styles = useStyles();
  const fieldTextProps = useCallback((name, value) => {
    return {
      autoComplete: 'off',
      fullWidth: true,
      InputLabelProps: {shrink: true},
      label: name,
      margin: 'dense',
      readOnly: true,
      value: value ? value : '',
      variant: 'outlined',
    };
  }, []);
  const fieldDateProps = useCallback((name, value) => {
    return {
      autoComplete: 'off',
      variant: 'outlined',
      readOnly: true,
      fullWidth: true,
      label: name,
      margin: 'dense',
      value: value ? moment(value).format('MM/DD/YYYY') : 'Pending',
    };
  }, []);

  if (!selectedReturn.ShipmentDetails) return null;
  return (
    <Paper className={styles.paper} elevation={2}>
      <Typography variant='h6' gutterBottom style={{width: '100%'}}>
        Return Shipment Information
      </Typography>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('Carrier', selectedReturn.ShipmentDetails.Carrier)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldDateProps('Pickup Date', selectedReturn.ShipmentDetails.PickupDate)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('Shipping Paper No.', selectedReturn.ShipmentDetails.ShippingPaper)} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
ViewShipmentInfo.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewShipmentInfo;
