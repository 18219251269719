import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {LocalShipping} from '@material-ui/icons';
import ModalScheduleShipment from './ModalScheduleShipment';

const ViewScheduleShipment = ({selectedReturn}) => {
  const [modal, setModal] = useState(false);

  return (
    <Fragment>
      {selectedReturn.Status === 'Request Received' && <ListItem button onClick={() => setModal(true)} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <LocalShipping />
        </ListItemIcon>
        <ListItemText primary='Schedule Shipment' />
      </ListItem>}
      <ModalScheduleShipment open={modal} close={() => setModal(false)} selectedReturn={selectedReturn} />
    </Fragment>
  );
};

ViewScheduleShipment.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewScheduleShipment;
