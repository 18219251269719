import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {List} from '@material-ui/core';
import {Call, LocalShipping, Settings, Assessment} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {LogoutItem, MenuItem} from './MenuList/index';

const MenuList = props => {
  const {currentUser} = useSelector(state => state.auth);
  const menuItems = [
    {Icon: LocalShipping, text: 'Battery Returns', link: '/portal/battery-returns', visible: ['Admin', 'Affiliate', 'Harley', 'H-D Net', 'KBI'].includes(currentUser?.group)},
    {Icon: Assessment, text: 'Reports', link: '/portal/reports', visible: ['Admin', 'KBI'].includes(currentUser?.group)},
    {Icon: Call, text: 'Contact Shipper', link: '/portal/contact-shipper', visible: ['Affiliate', 'H-D Net'].includes(currentUser?.group)},
    {Icon: Settings, text: 'Admin Settings', link: '/portal/admin-settings', visible: ['Admin', 'Harley', 'KBI'].includes(currentUser?.group)},
  ];
  const styles = useStyles();

  return (
    <Fragment>
      <div className={styles.toolbar} />
      <List component='nav' style={{padding: 0}}>
        {menuItems.map((item, index) => <MenuItem key={index} {...item} />)}
        <LogoutItem padding={15} />
      </List>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
    },
  },
}));
export default MenuList;
