import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {CloudUpload} from '@material-ui/icons';
import ModalUploadDocument from './ModalUploadDocument';


const ViewUploadDocument = ({selectedReturn}) => {
  const [modal, setModal] = useState(false);

  return (
    <Fragment>
      <ListItem button onClick={() => setModal(true)} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <CloudUpload />
        </ListItemIcon>
        <ListItemText primary='Upload Document' />
      </ListItem>
      <ModalUploadDocument open={modal} close={() => setModal(false)} selectedReturn={selectedReturn} />
    </Fragment>
  );
};

ViewUploadDocument.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewUploadDocument;
