import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {List, ListItem, Typography, ListItemIcon, ListItemText} from '@material-ui/core';
import {Print, VerifiedUser} from '@material-ui/icons';

const ViewUserActions = ({selectedReturn}) => {
  const menuSideProps = useMemo(() => ({
    style: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'ghostwhite',
      border: '1px solid darkgray',
      margin: '8px 0px',
    },
    subheader: <Typography variant='subtitle1' style={{marginLeft: '8px', marginTop: '8px'}}>User Actions</Typography>,
  }), []);
  const printPath = '/portal/battery-returns/details';

  return (
    <List {...menuSideProps}>
      <ListItem button component={Link} to={`${printPath}/${selectedReturn.BatteryReturnId}/print-details`} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <Print />
        </ListItemIcon>
        <ListItemText primary='Print Details' />
      </ListItem>
      {['Battery Received'].includes(selectedReturn.Status) && <ListItem button component={Link} to={`${printPath}/${selectedReturn.BatteryReturnId}/print-certificate`} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <VerifiedUser />
        </ListItemIcon>
        <ListItemText primary='Print Certificate' />
      </ListItem>
      }
    </List>
  );
};

ViewUserActions.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewUserActions;
