import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {AppBar, Toolbar, Typography, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Security, Menu} from '@material-ui/icons';

const MainAppBar = ({disableGutters, toggleDrawer}) => {
  const {currentUser} = useSelector(state => state.auth);
  const styles = useStyles();

  return (
    <div className={styles.grow}>
      <AppBar position="absolute" className={styles.appBar}>
        <Toolbar disableGutters={disableGutters}>
          <IconButton className={styles.navIconHide} color="inherit" aria-label="open drawer" onClick={() => toggleDrawer()}>
            <Menu />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>EV BATTERY RETURNS</Typography>
          <div className={styles.grow} />
          <div className={styles.sectionDesktop}>
            {currentUser?.group === 'Admin' && <Security style={{fontSize: '3em'}} />}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  navIconHide: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));
MainAppBar.propTypes = {
  disableGutters: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};
export default React.memo(MainAppBar);
