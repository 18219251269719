import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {Button, Collapse, Divider, Grid, Paper, Typography} from '@material-ui/core';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {ArrowBackIosTwoTone, Motorcycle, SupervisorAccount, VerifiedUser} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {object, string} from 'yup';
import logo from 'media/xEV-Strategies-Logo.png';
import {Alert, Formik} from '@kbi/component-library';
import {Auth, Functions} from 'firebaseConfig.js';

const SignInUser = ({history}) => {
  const {FormButton, FormikForm, SubmitButton, TextField} = Formik;
  const [stage, setStage] = useState('SignIn');
  const {userType} = useParams();
  const styles = useStyles();
  const core = useMemo(() => ({
    dealerSignIn: {
      color: 'primary',
      className: styles.dealerSignIn,
      fullWidth: true,
      variant: 'outlined',
      onClick: () => window.location = 'https://kbirecycling.com/',
    },
    forgotPassword: {
      gutterBottom: true,
      style: {
        cursor: 'pointer',
        float: 'right',
        marginTop: '4px',
      },
      variant: 'caption',
      onClick: () => {
        if (stage === 'SignIn') setStage('ForgotPassword');
        else if (stage === 'ForgotPassword') setStage('SignIn');
      },
    },
    submit: {
      color: 'primary',
      disableRipple: true,
      fullWidth: true,
      style: {marginTop: '24px'},
      type: 'submit',
      variant: 'contained',
    },
  }), [stage, styles]);
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {Email: '', Password: ''},
    onSubmit: (values, actions) => {
      const {Email, Password} = values;
      const handleSignIn = async () => {
        try {
          const {user: {uid, displayName, email}} = await Auth.signInWithEmailAndPassword(Email, Password);
          Functions.httpsCallable('saveUserLogin')({uid, displayName, email});
          history.replace('/portal');
        }
        catch (error) {
          actions.setStatus({text: error.message, in: true, severity: 'error'});
          actions.setSubmitting(false);
        }
      };
      const handleForgotPassword = async () => {
        try {
          await Auth.sendPasswordResetEmail(Email);
          actions.setStatus({text: 'We have sent you a password reset email.', in: true, severity: 'success'});
          actions.setSubmitting(false);
        }
        catch (error) {
          actions.setStatus({text: error.message, in: true, severity: 'error'});
          actions.setSubmitting(false);
        }
      };
      if (stage === 'SignIn') handleSignIn();
      else handleForgotPassword();
    },
    validationSchema: () => {
      const forgotPasswordValidation = object().shape({
        Email: string().required().email(),
      });
      const signInValidation = object().shape({
        Email: string().required().email(),
        Password: string().required().min(5),
      });
      if (stage === 'ForgotPassword') return forgotPasswordValidation;
      if (stage === 'SignIn') return signInValidation;
    },
  }), [history, stage]);
  const goBackButtonProps = useMemo(() => ({
    fullWidth: true,
    startIcon: <ArrowBackIosTwoTone />,
    variant: 'contained',
    onClick: () => history.replace('/signin'),
  }), [history]);

  return (
    <div className={styles.layout}>
      <Paper className={styles.paper} elevation={4}>
        <div style={{display: 'flex'}}>
          <img alt='EV Battery Returns Logo' src={logo} style={{height: '100px', marginBottom: '24px', marginTop: '24px'}} />
        </div>
        <Typography variant='h5'>Harley-Davidson</Typography>
        <Typography variant='h5' style={{fontSize: '1.2rem', marginTop: '16px'}}>Lithium Battery Return</Typography>
        <Typography variant='h5' style={{fontSize: '1.2rem'}}>Portal Sign In</Typography>
        <Collapse in={!userType} style={{width: '100%'}}>
          <List component='nav' className={styles.userList}>
            <Divider />
            <ListItem button onClick={() => history.push('/signin/dealership')}>
              <ListItemIcon className={styles.itemIcon}>
                <Motorcycle />
              </ListItemIcon>
              <ListItemText primary='Harley Dealerships' />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => history.push('/signin/affiliate')}>
              <ListItemIcon className={styles.itemIcon}>
                <SupervisorAccount />
              </ListItemIcon>
              <ListItemText primary='Harley Affiliates' />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => history.push('/signin/admin')}>
              <ListItemIcon className={styles.itemIcon}>
                <VerifiedUser />
              </ListItemIcon>
              <ListItemText primary='Portal Admins' />
            </ListItem>
            <Divider />
          </List>
        </Collapse>
        <Collapse in={userType === 'dealership'}>
          <div style={{marginTop: '16px'}}>
            <Typography variant='overline' style={{lineHeight: 1}}>
              Harley Dealership Users
            </Typography>
            <Button {...core.dealerSignIn}>Sign In Through harleydealer.com</Button>
          </div>
        </Collapse>
        <Collapse in={userType === 'affiliate' || userType === 'admin'} style={{width: '100%', paddingTop: '16px'}}>
          <FormikForm {...formProps}>
            {({isSubmitting, isValidating, setStatus, status}) => (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='overline' style={{lineHeight: 1}}>
                    {userType === 'affiliate' && 'Affiliate User Info'}
                    {userType === 'admin' && 'Admin User Info'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField name='Email' fast required autoComplete='username' />
                </Grid>
                {stage === 'SignIn' && <Grid item xs={12}>
                  <TextField name='Password' type='password' required autoComplete='current-password' />
                </Grid>}
                <Alert text={status.text} in={status.in} severity={status.severity} onClose={() => setStatus({...status, in: false})} />
                <Grid item xs={6}>
                  <FormButton {...goBackButtonProps}>Go Back</FormButton>
                </Grid>
                <Grid item xs={6}>
                  <SubmitButton fullWidth style={{marginTop: '24px'}}>
                    {stage === 'SignIn' && 'Sign In'}
                    {stage === 'ForgotPassword' && 'Reset Password'}
                  </SubmitButton>
                  <Typography {...core.forgotPassword}>
                    {stage === 'SignIn' && 'Forgot Password?'}
                    {stage === 'ForgotPassword' && 'Remembered Password?'}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </FormikForm>
        </Collapse>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  dealerSignIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  itemIcon: {
    color: theme.palette.primary.main,
  },
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  userList: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));
SignInUser.propTypes = {
  history: PropTypes.object,
};
export default SignInUser;
