import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const Loader = props => {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
};
const styles = {
  root: {flexGrow: 1},
};
export default withStyles(styles)(Loader);
