import React, {Fragment, useEffect, useMemo} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Paper, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ViewAdminActions, ViewAdminNotes, ViewBatteryInfo, ViewDealerInfo, ViewDetailsHeader, ViewFiles, ViewReturnOverview, ViewShipmentInfo, ViewUserActions} from './BatteryReturnDetails/';
import {PrintCertificate, PrintDetails} from './BatteryReturnDetails/';
import {listenBatteryReturnDetails} from 'state/actions/firestore';
import {AsyncLoader} from 'components';

const BatteryReturnDetails = () => {
  const {currentUser} = useSelector(state => state.auth);
  const {batteryReturns} = useSelector(state => state.firestore);
  const {BatteryReturnId, PrintView} = useParams();
  const styles = useStyles();
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenBatteryReturnDetails({currentUser, dispatch}), [currentUser]);
  const selectedReturn = useMemo(() => {
    // If batteryReturns.ref === null, then data not loaded yet and AsyncLoader should be rendered.
    if (batteryReturns.ref === null) return null;
    // else if !batteryReturns.ref[BatteryReturnId] then data does not exist and page should redirect to BatteryReturns table.
    else if (!batteryReturns.ref[BatteryReturnId]) return undefined;
    // else data is found and details page should be rendered.
    else return batteryReturns.ref[BatteryReturnId];
  }, [BatteryReturnId, batteryReturns]);

  if (selectedReturn === null) return <AsyncLoader />;
  if (selectedReturn === undefined) return <Redirect to={'/portal/battery-returns'} />;
  if (PrintView === 'print-certificate') return <PrintCertificate selectedReturn={selectedReturn} />; ;
  if (PrintView === 'print-details') return <PrintDetails selectedReturn={selectedReturn} />;
  return (
    <Fragment>
      <Grid spacing={2} container>
        <Grid item xs={12} md={3}>
          <Paper className={styles.paper} elevation={2} style={{flexDirection: 'column', alignItems: 'center'}}>
            <ViewDetailsHeader selectedReturn={selectedReturn} />
            <ViewUserActions selectedReturn={selectedReturn} />
            <ViewFiles selectedReturn={selectedReturn} />
            <ViewAdminActions selectedReturn={selectedReturn} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <ViewReturnOverview selectedReturn={selectedReturn} />
          <ViewDealerInfo selectedReturn={selectedReturn} />
          <ViewBatteryInfo selectedReturn={selectedReturn} />
          <ViewShipmentInfo selectedReturn={selectedReturn} />
          <ViewAdminNotes selectedReturn={selectedReturn} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
export default BatteryReturnDetails;
