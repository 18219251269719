import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {CircularProgress, Button, Paper, Typography} from '@material-ui/core';
import {CheckCircleOutline, Warning} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import jwtParser from 'jsonwebtoken';
import {Auth, Functions} from 'firebaseConfig.js';
import logo from 'media/xEV-Strategies-Logo.png';

const SignInSSO = ({history}) => {
  const [authStatus, setAuthStatus] = useState('pending');
  const [error, setError] = useState('');
  const {jwt} = useParams();
  const styles = useStyles();

  const validateToken = () => {
    const jwtSignIn = async () => {
      try {
        const {user: {userId, displayName, email}} = await Auth.signInWithCustomToken(jwt);
        const {claims, uid} = jwtParser.decode(jwt);
        await Functions.httpsCallable('updateHDNetUser')({...claims, uid});
        setAuthStatus('success');
        Functions.httpsCallable('saveUserLogin')({uid: userId, displayName, email});
        setTimeout(() => history.replace('/portal/battery-returns'), 3000);
      }
      catch (error) {
        if (error.code === 'auth/invalid-custom-token') {
          setError('Your SSO authorization token was invalid. Please login again.');
        }
        else setError(error.message);
      }
    };
    if (!jwt) setError('No Signin Token Provided.');
    else jwtSignIn();
  };
  useEffect(validateToken, [history, jwt]);

  return (
    <div className={styles.layout}>
      <Paper className={styles.paper} elevation={4}>
        <div style={{display: 'flex'}}>
          <img alt='EV Battery Returns Logo' src={logo} style={{height: '100px', marginBottom: '24px', marginTop: '24px'}} />
        </div>
        <Typography variant='h5'>Harley-Davidson</Typography>
        <Typography variant='h5' style={{fontSize: '1.2rem'}}>Lithium Battery Returns</Typography>
        {!error && authStatus === 'pending' && <ValidateJWT />}
        {!error && authStatus === 'success' && <ShowSuccess />}
        {error && <ShowError error={error} />}
      </Paper>
    </div>
  );
};

const ValidateJWT = () => {
  const styles = useStyles();
  const progressProps = useMemo(() => ({
    className: styles.progress,
    color: 'secondary',
    size: '15%',
    thickness: 1.5,
  }), [styles.progress]);
  return (
    <Fragment>
      <CircularProgress {...progressProps} />
      <Typography variant='h6'>Please Be Patient</Typography>
      <Typography variant='subtitle1'>Checking Dealer Authentication</Typography>
    </Fragment>
  );
};
const ShowSuccess = () => {
  const styles = useStyles();
  return (
    <Fragment>
      <CheckCircleOutline className={styles.success} color='primary' />
      <Typography variant='h6'>Authorization Successful</Typography>
      <Typography variant='subtitle1'>You will now be logged in.</Typography>
    </Fragment>
  );
};
const ShowError = ({error}) => {
  const styles = useStyles();
  const backButtonProps = useMemo(() => ({
    color: 'primary',
    href: 'https://h-dnet.com/',
    style: {margin: '16px'},
    variant: 'outlined',
    onClick: () => window.location = 'https://h-dnet.com/',
  }), []);
  return (
    <Fragment>
      <Warning className={styles.error} color='secondary' />
      <Typography variant='h6'>Authorization Error</Typography>
      <Typography variant='caption' style={{textAlign: 'center'}}>
        {error}
      </Typography>
      <Button {...backButtonProps}>Retry Login</Button>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  error: {
    fontSize: '6em',
    margin: theme.spacing(3),
  },
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  progress: {
    margin: theme.spacing(5),
  },
  success: {
    fontSize: '6em',
    margin: theme.spacing(3),
  },
}));
ShowError.propTypes = {
  error: PropTypes.string.isRequired,
};
SignInSSO.propTypes = {
  history: PropTypes.object,
};
export default SignInSSO;
