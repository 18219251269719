import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CheckCircleOutline} from '@material-ui/icons';
import {firestoreUtility} from 'utilities';
import {object, string} from 'yup';
import {Storage} from 'firebaseConfig';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {FormButton, FormikForm, SubmitButton, SelectField, TextField} = Formik;

const ModalUploadDocument = ({close, open, selectedReturn}) => {
  const [file, setFile] = useState([]);
  const [stage, setStage] = useState('dropfile');
  const [uploadProgress, setUploadProgress] = useState(0);
  const {currentUser} = useSelector(state => state.auth);
  const styles = useStyles();

  const fileTypeOptions = useMemo(() => ([
    {value: 'Shipping_Document', display: 'Shipping Document'},
    {value: 'Hazmat_Marking', display: 'Hazmat Marking'},
    {value: 'Shipping_Label', display: 'Shipping Label'},
    {value: 'Battery_Return_Form', display: 'Battery Return Form'},
  ]), []);
  const handleClose = useCallback(() => {
    setStage('dropfile');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {
      FileName: '',
      FileType: '',
    },
    onSubmit: (values, actions) => {
      setStage('upload');
      const {BatteryReturnId, Files} = selectedReturn;
      const {FileType} = values;
      const fileExt = file[0].name.split('.').pop();
      const metadata = {
        customMetadata: {
          BatteryReturnId: BatteryReturnId,
          FileType: fileTypeOptions[FileType].display,
          UploadedByName: currentUser.name,
          UploadedByUid: currentUser.uid,
          UploadedOn: moment().format('MM/DD/YYYY HH:mm:ss'),
        },
      };
      const storageRef = Storage.ref().child(`BatteryReturnFiles/${BatteryReturnId}/${fileTypeOptions[FileType].value}.${fileExt}`).put(file[0], metadata);
      const uploadProgress = snapshot => setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      const uploadSuccess = async () => {
        try {
          const downloadMetdata = await storageRef.snapshot.ref.getMetadata();
          if (downloadMetdata.size < 1024) throw new Error('File upload failed to finalize. Please try again.');
          const downloadURL = await storageRef.snapshot.ref.getDownloadURL();
          const updateDocument = {
            collection: 'BatteryReturns',
            writeType: 'update',
            docId: BatteryReturnId,
            docFields: {
              Files: {...Files, [fileTypeOptions[FileType].value]: downloadURL},
            },
          };
          await firestoreUtility(updateDocument);
          setStage('success');
        }
        catch (error) {
          actions.setStatus({text: error.message, in: true, severity: 'error'});
          if (error.message === 'File upload failed to finalize. Please try again.') setStage('dropfile');
          actions.setSubmitting(false);
        }
      };
      const uploadError = error => actions.setStatus({text: error.message, in: true, severity: 'error'});
      storageRef.on('state_changed', uploadProgress, uploadError, uploadSuccess);
    },
    validationSchema: object().shape({
      FileType: string().label('File Type').required(),
    }),
  }), [currentUser.name, currentUser.uid, file, fileTypeOptions, selectedReturn]);
  const dropzoneProps = useCallback(({setFieldValue, setStatus}) => {
    return {
      accept: ['application/pdf'],
      maxSize: 5242880,
      minSize: 1024,
      multiple: false,
      onDropAccepted: file => {
        setFile(file);
        setFieldValue('FileName', file[0].name);
        setStage('information');
      },
      onDropRejected: fileRejections => {
        const fileExtension = fileRejections[0].file.name.split('.').pop();
        if (fileRejections[0].file.size < 1024) {
          setStatus({text: 'The file you selected was too small.', in: true, severity: 'error'});
        }
        else if (fileRejections[0].file.size > 5242880) {
          setStatus({text: 'The file you selected was too large.', in: true, severity: 'error'});
        }
        else if (fileExtension.toLowerCase() !== 'pdf') {
          setStatus({text: 'The file you upload must be a pdf.', in: true, severity: 'error'});
        }
        else setStatus({text: fileRejections[0].errors[0].message, in: true, severity: 'error'});
      },
      onFileDialogCancel: () => {
        setFile([]);
        setFieldValue('FileName', '');
      },
    };
  }, []);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({setStatus, status, isSubmitting, isValidating, setFieldValue}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success' && stage !== 'upload'}>
                Upload Document
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'dropfile'}>
                <Dropzone {...dropzoneProps({setFieldValue, setStatus})}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className={styles.fileDrop}>
                      <input {...getInputProps()} />
                      <div className={styles.fileDropText}>
                        <Typography variant='subtitle1'>Drop File Here or Click Inside Box to Select</Typography>
                        <Typography variant='body2'>(File Must Be a PDF Under 5mb)</Typography>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Collapse>
              <Collapse in={stage === 'information'}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField name='FileName' label='File Name' disabled />
                  </Grid>
                  <Grid item xs={12} sm>
                    <SelectField name='FileType' label='File Type' select>
                      {fileTypeOptions.map((option, index) => (
                        <MenuItem key={option.value} value={index}>
                          {option.display}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'upload'}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '24px'}}>
                  <Typography variant='h3' gutterBottom>
                    Upload Progress
                  </Typography>
                  <Typography variant='h1'>{uploadProgress.toFixed(0)}%</Typography>
                </div>
              </Collapse>
              <Collapse in={stage === 'success'}>
                <DialogContent>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CheckCircleOutline style={{fontSize: '10em'}} />
                    <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                      File has been successfully uploaded.
                    </Typography>
                  </div>
                </DialogContent>
              </Collapse>
              <Alert text={status.text} in={status.in} severity={status.severity} onClose={() => setStatus({...status, in: false})} />
            </DialogContent>
            <Collapse in={stage === 'dropfile'}>
              <DialogActions>
                <FormButton onClick={() => handleClose()} color='secondary' variant='text'>
                  Cancel
                </FormButton>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'information'}>
              <DialogActions>
                <FormButton onClick={() => handleClose()} color='secondary' variant='text'>Cancel</FormButton>
                <SubmitButton type='submit' color='primary' variant='text'>Upload</SubmitButton>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <Button onClick={handleClose} color='primary'>Close</Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  fileDrop: {
    alignItems: 'center',
    backgroundColor: 'aliceblue',
    border: '2px gray dotted',
    display: 'flex',
    height: '200px',
    justifyContent: 'center',
  },
  fileDropText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
ModalUploadDocument.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedReturn: PropTypes.object.isRequired,
};
export default ModalUploadDocument;
