import React, {useCallback, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, DialogContentText} from '@material-ui/core';
import {Button, Grid} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
import moment from 'moment';
import {object, string, date} from 'yup';
const {FormikForm, SelectField, DateField} = Formik;

const ReportOptionsModal = ({close, open, history, setReport}) => {
  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open: open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);

  const formProps = useMemo(() => ({
    initialValues: {
      ReportType: '',
      Start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      End: moment().format('YYYY-MM-DD'),
    },
    onSubmit: (values, actions) => {
      if ((values.End && values.Start) && !moment(values.End).isAfter(values.Start)) {
        actions.setFieldError('Start', 'Start must be before End');
        actions.setFieldError('End', 'End must be after Start');
        actions.setSubmitting(false);
      }
      else {
        setReport({type: values.ReportType, dateStart: values.Start, dateEnd: values.End});
      }
    },
    validationSchema: object().shape({
      ReportType: string().required(),
      Start: date(),
      End: date(),
    }),
  }), [setReport]);
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({values}) => (
          <>
            <DialogTitle>
            Generate Report
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Select the report and the period over which to pull data.</DialogContentText>
              <Grid container spacing={2}>
                {!values.ReportType ?
                  <Grid item xs={12}>
                    <SelectField name='ReportType' label='Report Type' required>
                      <MenuItem value='warranty-report'>Warranty Reports</MenuItem>
                      <MenuItem value='user-logs'>User Logs</MenuItem>
                      <MenuItem value='billing-summary'>Billing Summary</MenuItem>
                    </SelectField>
                  </Grid> :
                  <>
                    <Grid item xs={12} sm={4}>
                      <SelectField name='ReportType' label='Report Type' required>
                        <MenuItem value='warranty-report'>Warranty Reports</MenuItem>
                        <MenuItem value='user-logs'>User Logs</MenuItem>
                        <MenuItem value='billing-summary'>Billing Summary</MenuItem>
                      </SelectField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DateField name='Start' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DateField name='End' />
                    </Grid>
                  </>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
              Cancel
              </Button>
              <Button color='primary' type='submit'>
                Generate
              </Button>
            </DialogActions>
          </>
        )
        }
      </FormikForm>
    </Dialog>
  );
};

ReportOptionsModal.propTypes = {
  history: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setReport: PropTypes.func.isRequired,
};

export default withRouter(ReportOptionsModal);
