import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Send} from '@material-ui/icons';
import ModalEmailDocuments from './ModalEmailDocuments';

const ViewEmailDocuments = ({fileCount, selectedReturn}) => {
  const [modal, setModal] = useState(false);
  if (!fileCount) return null;
  return (
    <Fragment>
      <ListItem button onClick={() => setModal(true)} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <Send />
        </ListItemIcon>
        <ListItemText primary={`Email Document${fileCount > 1 ? 's' : ''}`} />
      </ListItem>
      <ModalEmailDocuments open={modal} close={() => setModal(false)} selectedReturn={selectedReturn} />
    </Fragment>
  );
};

ViewEmailDocuments.propTypes = {
  fileCount: PropTypes.number.isRequired,
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewEmailDocuments;
