import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Button, Collapse, Grid, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {boolean, object, string} from 'yup';
import {acAddPortalUser, acUpdatePortalUser} from 'state/actions/auth';
import {Alert, Formik} from '@kbi/component-library';
const {AutoComplete, FormikForm, SelectField, TextField} = Formik;

const PortalUserModal = ({close, row, type}) => {
  const [alert, setAlert] = useState({text: '', in: false, severity: 'warning'});
  const [stage, setStage] = useState('enterPortalUser');
  const {portalUsers} = useSelector(state => state.auth);
  const {affiliateSites} = useSelector(state => state.firestore);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setStage('enterPortalUser');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open: type === 'addPortalUser' || type === 'editPortalUser',
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [type]);
  const formProps = useMemo(() => ({
    initialValues: (() => {
      const isEdit = type === 'editPortalUser';
      return {
        active: isEdit && row ? row.active : true,
        displayName: isEdit && row ? row.displayName : '',
        email: isEdit && row ? row.email : '',
        password: isEdit ? '' : Math.random().toString(36).slice(-8),
        group: isEdit && row ? row.group : '',
        affiliateId: isEdit && row?.group === 'Affiliate' && row.affiliateId ? row.affiliateId : '',
      };
    })(),
    onSubmit: (values, actions) => {
      (async () => {
        const {active, affiliateId, displayName, email, group, password} = values;
        try {
          if (type === 'addPortalUser') {
            await acAddPortalUser({affiliateId, dispatch, displayName, email, group, password, portalUsers});
          }
          else if (type === 'editPortalUser') {
            await acUpdatePortalUser({active, affiliateId, dispatch, displayName, email, group, portalUsers, uid: row.uid});
          }
          setStage('success');
        }
        catch (error) {
          setAlert({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: () => {
      const addUserValidation = object().shape({
        active: boolean().label('Active').required(),
        displayName: string().label('Display Name').required(),
        email: string().label('Email').required().email(),
        group: string().label('Group').required(),
        password: string().label('Password').required().min(8),
        affiliateId: string().label('Affiliate ID').when('group', {
          is: 'Affiliate',
          then: string().required(),
          otherwise: string().notRequired(),
        }),
      });
      const editUserValidation = object().shape({
        active: boolean().label('Active').required(),
        displayName: string().label('Display Name').required(),
        email: string().label('Email').required().email(),
        group: string().label('Group').required(),
        affiliateId: string().label('Affiliate ID').when('group', {
          is: 'Affiliate',
          then: string().required(),
          otherwise: string().notRequired(),
        }),
      });
      if (type === 'addPortalUser') return addUserValidation;
      if (type === 'editPortalUser') return editUserValidation;
    },
  }), [dispatch, portalUsers, row, type]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({isSubmitting, isValidating, values}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                {type === 'addPortalUser' ? 'Create New' : 'Update'} Portal User
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'enterPortalUser'} timeout={{enter: 500, exit: 500}}>
                <DialogContentText>Enter receiving site details below:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SelectField name='active' label='Active' disabled={Boolean(type === 'addPortalUser')} required>
                      <MenuItem key={true} value={true}>
                        True
                      </MenuItem>
                      <MenuItem key={false} value={false}>
                        False
                      </MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='displayName' label='Display Name' required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='email' label='Email' required />
                  </Grid>
                  {type === 'addPortalUser' && <Grid item xs={12} sm={6}>
                    <TextField name='password' label='Password' required />
                  </Grid>}
                  <Grid item xs={12} sm={6}>
                    <SelectField name='group' label='Group' required>
                      {['Admin', 'Affiliate', 'Harley', 'KBI'].map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  {values.group === 'Affiliate' && <Grid item xs={12} sm={6}>
                    <AutoComplete name='affiliateId' label='Affiliate ID' options={affiliateSites?.list || []} optionKey='AffiliateSiteId' />
                  </Grid>}
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    Portal user successfully {type === 'addPortalUser' ? 'added.' : 'updated.'}
                  </Typography>
                </div>
              </Collapse>
              <Alert text={alert.text} in={alert.in} severity={alert.severity} onClose={() => setAlert({...alert, in: false})} />
            </DialogContent>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color='secondary' disabled={isSubmitting || isValidating}>
                  Cancel
                </Button>
                <Button type='submit' color='primary' disabled={isSubmitting || isValidating}>
                  Submit
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

PortalUserModal.propTypes = {
  row: PropTypes.object,
  type: PropTypes.string,
  close: PropTypes.func.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (!prevProps.type && (nextProps.type === 'addPortalUser' || nextProps.type === 'editPortalUser')) return false;
  else if ((prevProps.type === 'addPortalUser' || prevProps.type === 'editPortalUser') && !nextProps.type) return false;
  return true;
};
export default React.memo(PortalUserModal, isMemoEqual);
