import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const ViewDealerInfo = ({selectedReturn}) => {
  const styles = useStyles();
  const fieldTextProps = useCallback((name, value) => {
    return {
      autoComplete: 'off',
      fullWidth: true,
      InputLabelProps: {shrink: true},
      label: name,
      margin: 'dense',
      readOnly: true,
      value: value ? value : '',
      variant: 'outlined',
    };
  }, []);

  return (
    <Paper className={styles.paper} elevation={2}>
      <Typography variant='h6' gutterBottom style={{width: '100%'}}>
        Pickup Information
      </Typography>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={6}>
          {selectedReturn.LocationType === 'H-D Net' && <TextField {...fieldTextProps('Dealer Name (No.)', `${selectedReturn.DealerName} (${selectedReturn.DealerNo})`)} />}
          {selectedReturn.LocationType === 'Affiliate' && <TextField {...fieldTextProps('Affiliate Name', `${selectedReturn.AffiliateName}`)} />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...fieldTextProps(
            'Hazmat Certification',
            selectedReturn.Hazmat.Certified ? 'On-Site Hazmat Certified Employee' : 'Third-party Hazmat Packaging Required')
          } />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...fieldTextProps('Address 1', selectedReturn.PickupLocation.Address1)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField {...fieldTextProps('Address 2', selectedReturn.PickupLocation.Address2)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('City', selectedReturn.PickupLocation.City)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps(
            (() => {
              if (selectedReturn.PickupLocation.Country === 'United States') return 'State';
              if (selectedReturn.PickupLocation.Country === 'Canada') return 'Province';
            })(),
            selectedReturn.PickupLocation.StateProvince)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Postal Code', selectedReturn.PickupLocation.PostalCode)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Country', selectedReturn.PickupLocation.Country)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Shipping Contact', selectedReturn.Contact.Name)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Shipping Phone', selectedReturn.Contact.Phone)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Shipping Fax', selectedReturn.Contact.Fax)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Shipping Email', selectedReturn.Contact.Email)} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
ViewDealerInfo.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewDealerInfo;
