import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {CheckCircleOutline, Clear} from '@material-ui/icons';
import {FieldArray} from 'formik';
import {array, number, object, string} from 'yup';
import {firestoreUtility} from 'utilities';
import {FieldValue} from 'firebaseConfig';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {AutoCompleteValue, CurrencyField, FormButton, FormikForm, SubmitButton} = Formik;

const ModalFinalizeAmount = ({close, open, selectedReturn}) => {
  const [stage, setStage] = useState('amount');
  const handleClose = useCallback(() => {
    setStage('amount');
    close();
  }, [close]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {
      Lines: selectedReturn?.Accounting.Lines.map(item => {
        return {Description: item.Description, Amount: item?.Amount?.toFixed(2) || '0.00'};
      }) || [],
    },
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const updateDocument = {
            collection: 'BatteryReturns',
            writeType: 'update',
            docId: selectedReturn.BatteryReturnId,
            docFields: {
              Accounting: {
                DateClosed: FieldValue.serverTimestamp(),
                Lines: values.Lines.map(line => {
                  return {Amount: Number(line.Amount), Description: line.Description};
                }),
                Status: 'Closed',
                Total: Number(values.Lines.reduce((acc, cv) => acc + (cv.Amount ? parseInt(cv.Amount, 10) : 0), 0).toFixed(2)),
              },
              Date: {...selectedReturn.Date, Reminder: null},
            },
          };
          await firestoreUtility(updateDocument);
          setStage('success');
          setTimeout(() => actions.setSubmitting(false), 750);
        }
        catch (error) {
          actions.setStatus({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      Lines: array().of(
        object().shape({
          Amount: number().label('Amount').required('Required field'),
          Description: string().label('Description').min(3, 'too short').required('Required field'),
        }),
      ),
    }),
  }), [selectedReturn.BatteryReturnId, selectedReturn.Accounting, selectedReturn.Date]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({setStatus, status, isSubmitting, isValidating, setFieldValue, values}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'}>Finalize Amount</Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'amount'}>
                <FieldArray name='Lines'>{arrayHelpers => (
                  <Fragment>
                    <div style={{backgroundColor: 'azure', border: '1px solid lightgray', marginBottom: '16px', padding: '8px'}}>
                      <Typography>Charges/Credits</Typography>
                      {values.Lines.map((item, index, array) => (
                        <Grid container spacing={1} key={index} style={{alignItems: 'flex-start'}}>
                          <Grid item xs={3}>
                            <CurrencyField name={`Lines[${index}].Amount`} label='Amount' />
                          </Grid>
                          <Grid item xs={6}>
                            <AutoCompleteValue options={feeOptions.map(item => ({value: item}))} optionKey='value' freeSolo name={`Lines[${index}].Description`} label='Description' />
                          </Grid>
                          <Grid item xs={2} style={{alignSelf: 'center'}}>
                            <FormButton style={{padding: '4px'}} color='secondary' variant='outlined' disabled={array.length === 1} onClick={() => arrayHelpers.remove(index)} fullWidth>
                              <Clear />
                            </FormButton>
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <FormButton color='primary' variant='outlined' onClick={() => arrayHelpers.push({Description: '', Amount: '0.00'})}>
                        Add Charge/Credit
                      </FormButton>
                      <Typography variant='h6'>
                        Total Amount: ${(values.Lines.reduce((acc, cv) => acc + (cv.Amount ? parseInt(cv.Amount, 10) : 0), 0)).toFixed(2)}
                      </Typography>
                    </div>
                  </Fragment>
                )}</FieldArray>
              </Collapse>
              <Collapse in={stage === 'success'}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    Reminder has been successfully updated.
                  </Typography>
                </div>
              </Collapse>
              <Alert text={status.text} in={status.in} severity={status.severity} onClose={() => setStatus({...status, in: false})} />
            </DialogContent>
            <Collapse in={stage !== 'success'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='secondary' variant='text'>Cancel</FormButton>
                <SubmitButton color='primary' variant='text'>Submit</SubmitButton>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <FormButton onClick={handleClose} color='primary' variant='text'>Close</FormButton>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

const feeOptions = ['Battery Recycling Fee', 'Transportation Fee', 'Tracking Admin Fee', 'Other: '];
ModalFinalizeAmount.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedReturn: PropTypes.object.isRequired,
};
export default React.memo(ModalFinalizeAmount);
