import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';

const ViewReturnOverview = ({selectedReturn}) => {
  const styles = useStyles();
  const fieldTextProps = useCallback((name, value) => {
    return {
      autoComplete: 'off',
      variant: 'outlined',
      readOnly: true,
      fullWidth: true,
      label: name,
      margin: 'dense',
      value: value ? value : '',
    };
  }, []);
  const fieldDateProps = useCallback((name, value) => {
    return {
      autoComplete: 'off',
      variant: 'outlined',
      readOnly: true,
      fullWidth: true,
      label: name,
      margin: 'dense',
      value: value ? moment(value).format('MM/DD/YYYY') : 'Pending',
    };
  }, []);

  return (
    <Paper className={styles.paper} elevation={2}>
      <Typography variant='h6' gutterBottom style={{width: '100%'}}>
        Return Overview
      </Typography>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Return No.', selectedReturn.ReturnNo)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldTextProps('Return Status', selectedReturn.Status)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldDateProps('Date Return Requested', selectedReturn.Date.Requested)} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField {...fieldDateProps('Date Battery Received', selectedReturn.Date.Received)} />
        </Grid>
        {selectedReturn.Accounting.Status === 'Closed' && (
          <Grid item xs={12}>
            <TextField {...fieldTextProps('Accounting', selectedReturn.Accounting.Lines.reduce((acc, cv) => {
              const usd = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
              return acc + `${cv.Description} (${usd.format(cv.Amount)}), `;
            }, ''))} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
ViewReturnOverview.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewReturnOverview;
