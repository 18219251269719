import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import {CloudDownload, More} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import exportToCSV from 'ExportToCSV';
import {Firestore} from 'firebaseConfig';
import moment from 'moment';

const UserLogsTable = ({selectedReport, goBack}) => {
  const [userLogs, setLogs] = useState(undefined);
  useEffect(() => {
    Firestore.collection('UserLogs').get().then(snap => {
      const usrLogs = [];
      snap.forEach(doc => {
        const data = doc.data();
        const logRecord = {...data, Time: data.Time.toDate(), FormattedTime: moment(data.Time.toDate()).format('lll')};
        if (selectedReport.dateStart || selectedReport.dateEnd) { // If either date exists, filtter accordingly.
          if (moment(logRecord.Time).isSame(selectedReport.dateStart, 'day') || moment(logRecord.Time).isSame(selectedReport.dateEnd, 'day')) {
            usrLogs.push(logRecord);
          }
          else if (selectedReport.dateStart && selectedReport.dateEnd) {
            if (moment(logRecord.Time).isAfter(selectedReport.dateStart, 'day') && moment(logRecord.Time).isBefore(selectedReport.dateEnd, 'day')) {
              usrLogs.push(logRecord);
            }
          }
          else if (moment(logRecord.Time).isAfter(selectedReport.dateStart, 'day')) usrLogs.push(logRecord);
          else if (moment(logRecord.Time).isBefore(selectedReport.dateEnd, 'day')) usrLogs.push(logRecord);
        }
        else {
          // If no filters exist, return all
          usrLogs.push(logRecord);
        }
      });
      setLogs(usrLogs);
    });
  }, [selectedReport.dateEnd, selectedReport.dateStart]);

  const columns = useMemo(() => ([
    {Header: 'Email', accessor: 'Email'},
    {Header: 'IP', accessor: 'IP'},
    {Header: 'Name', accessor: 'Name'},
    {Header: 'Time', accessor: 'FormattedTime', type: 'datetime', datetimeFormat: 'lll'},
    {Header: 'User ID', accessor: 'UID'},
  ]), []);
  const btnMore = useMemo(() => ({
    color: 'primary',
    style: {margin: '16px 0px'},
    startIcon: <More />,
    variant: 'outlined',
    onClick: goBack,
  }), [goBack]);
  const exportButton = useMemo(() => ({
    color: 'primary',
    startIcon: <CloudDownload />,
    style: {marginLeft: '16px'},
    variant: 'outlined',
    onClick: () => exportToCSV(userLogs, columns, 'user-logs.csv'),
  }), [columns, userLogs]);
  const tableProps = useMemo(() => ({
    columns,
    data: userLogs || [],
    sortInitial: [{id: 'FormattedTime', desc: true}],
    isLoading: !userLogs,
  }), [userLogs, columns]);

  return (
    <Fragment>
      <Button {...btnMore}>More Reports</Button>
      <Button {...exportButton}>Export</Button>
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>User Login History</Typography>
        <Table {...tableProps} />
      </Paper>
    </Fragment>
  );
};

UserLogsTable.propTypes = {
  selectedReport: PropTypes.shape({
    type: PropTypes.string.isRequired,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
};
export default UserLogsTable;
