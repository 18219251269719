import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Paper, Typography} from '@material-ui/core';
import {Assessment} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import ReportOptionsModal from './ReportOptionsModal';

const MainView = ({setReport}) => {
  const styles = useStyles();
  const [modal, setModal] = useState(false);
  const handleClick = useCallback(() => {
    setModal(true);
  }, []);
  const close = useCallback(() => {
    setModal(false);
  }, []);
  return (
    <>
      <Paper className={styles.paper} elevation={2}>
        <Typography align='center' variant='h4' gutterBottom>Administrative Reports</Typography>
        <Assessment color='primary' style={{fontSize: '10em', width: '100%'}} />
        <Typography align='center' variant='h6'>No Report Currently Generated</Typography>
        <ButtonGroup variant='text' color='primary'>
          <Button onClick={handleClick} variant='outlined'>Choose Report</Button>
        </ButtonGroup>
      </Paper>
      <ReportOptionsModal open={modal} close={close} setReport={setReport} />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `16px 24px 24px`,
  },
}));

MainView.propTypes = {
  setReport: PropTypes.func.isRequired,
};
export default MainView;
