import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import {CloudDownload, More, Search} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import exportToCSV from 'ExportToCSV';
import {Firestore} from 'firebaseConfig';
import moment from 'moment';

function openWindow(url) {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
}

const BillingSummaryTable = ({selectedReport, goBack}) => {
  const [billingSummary, setBillingSummary] = useState(undefined);

  useEffect(() => {
    const longAgo = new Date('1870-01-01'); // Only want summaries where "Accounting.DateClosed" exists. (it can be null)
    Firestore.collection('BatteryReturns').where('Accounting.DateClosed', '>', longAgo).get().then(snap => {
      const summaries = [];
      snap.forEach(doc => {
        const data = doc.data();
        const summaryRecord = {
          ...data,
          Accounting: {
            ...data.Accounting,
            Total: `$${data.Accounting.Total.toFixed(2)}`,
            DateClosed: data.Accounting.DateClosed.toDate(),
            Lines: data.Accounting.Lines.map(line => `${line.Description} ($${line.Amount.toFixed(2)})`).reduce((acc, cV) => acc + `, ${cV}`),
          },
          BatteryReturnId: doc.id,
        };
        if (selectedReport.dateStart || selectedReport.dateEnd) { // If either date exists, filtter accordingly.
          if (moment(summaryRecord.Accounting.DateClosed).isSame(selectedReport.dateStart, 'day') || moment(summaryRecord.Accounting.DateClosed).isSame(selectedReport.dateEnd, 'day')) {
            summaries.push(summaryRecord);
          }
          else if (selectedReport.dateStart && selectedReport.dateEnd) {
            if (moment(summaryRecord.Accounting.DateClosed).isAfter(selectedReport.dateStart, 'day') && moment(summaryRecord.Accounting.DateClosed).isBefore(selectedReport.dateEnd, 'day')) {
              summaries.push(summaryRecord);
            }
          }
          else if (moment(summaryRecord.Accounting.DateClosed).isAfter(selectedReport.dateStart, 'day')) summaries.push(summaryRecord);
          else if (moment(summaryRecord.Accounting.DateClosed).isBefore(selectedReport.dateEnd, 'day')) summaries.push(summaryRecord);
        }
        else {
          // If no filters exist, return all
          summaries.push(summaryRecord);
        }
      });
      setBillingSummary(summaries);
    });
  }, [selectedReport.dateEnd, selectedReport.dateStart]);

  const columns = useMemo(() => ([
    {Header: 'Return No.', accessor: 'ReturnNo'},
    {Header: 'Location', accessor: 'DealerNo'},
    {Header: 'Date Closed', accessor: 'Accounting.DateClosed', type: 'datetime'},
    {Header: 'Part No.', accessor: 'Battery.PartNo'},
    {Header: 'VIN', accessor: 'BatteryVin'},
    {Header: 'Total', accessor: 'Accounting.Total'},
    {Header: 'Details', accessor: 'Accounting.Lines'},
  ]), []);
  const btnMore = useMemo(() => ({
    color: 'primary',
    style: {margin: '16px 0px'},
    startIcon: <More />,
    variant: 'outlined',
    onClick: goBack,
  }), [goBack]);
  const exportButton = useMemo(() => ({
    color: 'primary',
    startIcon: <CloudDownload />,
    style: {marginLeft: '16px'},
    variant: 'outlined',
    onClick: () => exportToCSV(billingSummary, columns, 'billing-summary.csv'),
  }), [columns, billingSummary]);
  const tableProps = useMemo(() => ({
    actionsPerRow: [
      {icon: Search, tooltip: 'View Details', onClick: ({rowData}) => openWindow(`/portal/battery-returns/details/${rowData.BatteryReturnId}`)},
    ],
    columns,
    data: billingSummary || [],
    sortInitial: [{id: 'Accounting.DateClosed', desc: false}],
    isLoading: !billingSummary,
  }), [billingSummary, columns]);

  return (
    <Fragment>
      <Button {...btnMore}>More Reports</Button>
      <Button {...exportButton}>Export</Button>
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>Billing Summary</Typography>
        <Table {...tableProps} />
      </Paper>
    </Fragment>
  );
};

BillingSummaryTable.propTypes = {
  selectedReport: PropTypes.shape({
    type: PropTypes.string.isRequired,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
};
export default BillingSummaryTable;
