import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {BatteryFull} from '@material-ui/icons';
import ModalReceiveBattery from './ModalReceiveBattery';

const ViewReceiveBattery = ({selectedReturn}) => {
  const [modal, setModal] = useState(false);

  return (
    <Fragment>
      {selectedReturn.Status === 'Shipment Scheduled' && <ListItem button onClick={() => setModal(true)} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <BatteryFull />
        </ListItemIcon>
        <ListItemText primary='Receive Battery' />
      </ListItem>}
      <ModalReceiveBattery open={modal} close={() => setModal(false)} selectedReturn={selectedReturn} />
    </Fragment>
  );
};

ViewReceiveBattery.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewReceiveBattery;
