import React, {Fragment, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import ModalEditReturn from './ModalEditReturn';
import moment from 'moment';

const ViewEditReturn = ({selectedReturn, currentUser}) => {
  const [modal, setModal] = useState(false);
  const handleClick = useCallback(() => setModal(true), []);
  if (currentUser.group === 'KBI' && moment(selectedReturn.Date.Received).isBefore(moment().subtract(1, 'days'))) {
    return null;
  }
  else {
    return (
      <Fragment>
        <ListItem button onClick={handleClick} style={{padding: '8px'}}>
          <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
            <Edit />
          </ListItemIcon>
          <ListItemText primary='Edit Details' />
        </ListItem>
        <ModalEditReturn open={modal} close={() => setModal(false)} selectedReturn={selectedReturn} />
      </Fragment>
    );
  }
};

ViewEditReturn.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};
export default ViewEditReturn;
