import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import {CloudDownload, More} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import exportToCSV from 'ExportToCSV';
import {Firestore} from 'firebaseConfig';
import moment from 'moment';

const WarrantyTable = ({selectedReport, goBack}) => {
  const [warrantyReturns, setReturns] = useState(undefined);
  useEffect(() => {
    Firestore.collection('BatteryReturns').where('Battery.Warranty', '==', 'Yes').where('Status', '==', 'Battery Received').get().then(snap => {
      const returns = [];
      snap.forEach(doc => {
        const data = doc.data();
        const returnRecord = {...data, Date: {...data.Date, Received: data.Date.Received.toDate(), Requested: data.Date.Requested.toDate()}, docId: doc.id};
        if (data.LocationType === 'Affiliate') {
          returnRecord.DealerNo = data.AffiliateId;
        }
        if (selectedReport.dateStart || selectedReport.dateEnd) { // If either date exists, filtter accordingly.
          if (moment(returnRecord.Date.Requested).isSame(selectedReport.dateStart, 'day') || moment(returnRecord.Date.Requested).isSame(selectedReport.dateEnd, 'day')) {
            returns.push(returnRecord);
          }
          else if (selectedReport.dateStart && selectedReport.dateEnd) {
            if (moment(returnRecord.Date.Requested).isAfter(selectedReport.dateStart, 'day') && moment(returnRecord.Date.Requested).isBefore(selectedReport.dateEnd, 'day')) {
              returns.push(returnRecord);
            }
          }
          else if (moment(returnRecord.Date.Requested).isAfter(selectedReport.dateStart, 'day')) returns.push(returnRecord);
          else if (moment(returnRecord.Date.Requested).isBefore(selectedReport.dateEnd, 'day')) returns.push(returnRecord);
        }
        else {
          // If no filters exist, return all
          returns.push(returnRecord);
        }
      });
      setReturns(returns);
    });
  }, [selectedReport.dateEnd, selectedReport.dateStart]);

  const columns = useMemo(() => (
    [
      {Header: 'Dealer/Affiliate', accessor: 'DealerNo'},
      {Header: 'VIN', accessor: 'BatteryVin'},
      {Header: 'RESS SN', accessor: 'Battery.SerialNoReceived'},
      {Header: 'Warranty', accessor: 'Battery.Warranty'},
      {Header: 'Authorization No.', accessor: 'Battery.WarrantyAuth'},
      {Header: 'Request Date', accessor: 'Date.Requested', type: 'datetime', defaultSort: 'desc', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Received Date', accessor: 'Date.Received', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Return No.', accessor: 'ReturnNo'},
    ]
  ), []);

  const btnMore = useMemo(() => ({
    color: 'primary',
    style: {margin: '16px 0px'},
    startIcon: <More />,
    variant: 'outlined',
    onClick: goBack,
  }), [goBack]);

  const exportButton = useMemo(() => ({
    color: 'primary',
    startIcon: <CloudDownload />,
    style: {marginLeft: '16px'},
    variant: 'outlined',
    onClick: () => exportToCSV(warrantyReturns, columns, 'warranty-returns.csv'),
  }), [columns, warrantyReturns]);

  const tableProps = useMemo(() => ({
    columns,
    data: warrantyReturns || [],
    disableFilters: true,
    isLoading: !warrantyReturns,
  }), [warrantyReturns, columns]);

  return (
    <>
      <Button {...btnMore}>More Reports</Button>
      <Button {...exportButton}>Export</Button>
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>Warrantied Battery Returns</Typography>
        <Table {...tableProps} />
      </Paper>
    </>
  );
};

WarrantyTable.propTypes = {
  selectedReport: PropTypes.shape({
    type: PropTypes.string.isRequired,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
};

export default WarrantyTable;
