import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const ViewBatteryInfo = ({selectedReturn}) => {
  const styles = useStyles();
  const fieldTextProps = useCallback((name, value) => {
    return {
      autoComplete: 'off',
      fullWidth: true,
      InputLabelProps: {shrink: true},
      label: name,
      margin: 'dense',
      readOnly: true,
      value: value ? value : '',
      variant: 'outlined',
    };
  }, []);

  return (
    <Paper className={styles.paper} elevation={2}>
      <Typography variant='h6' gutterBottom style={{width: '100%'}}>
        Battery Information
      </Typography>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('Battery Name', selectedReturn.Battery.Name)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('Part No.', selectedReturn.Battery.PartNo)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('Serial No.', selectedReturn.Battery.SerialNoShipped)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('VIN', selectedReturn.BatteryVin)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('Warranty Return', selectedReturn.Battery.Warranty)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...fieldTextProps('Warranty Auth No.', selectedReturn.Battery.WarrantyAuth)} />
        </Grid>
        {selectedReturn.Battery.Hazards.length > 0 && (
          <Grid item xs={12}>
            <TextField multiline={true} {...fieldTextProps('Identified Hazards', selectedReturn.Battery.Hazards.join(' '))} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
ViewBatteryInfo.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewBatteryInfo;
