import React from 'react';
import PropTypes from 'prop-types';
import {Auth, Functions} from 'firebaseConfig.js';
import {Typography} from '@material-ui/core';
import {NotInterested} from '@material-ui/icons';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
    this.errorStyle = {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      paddingBottom: '200px',
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if (process.env.REACT_APP_ENV === 'production') {
      Functions.httpsCallable('errorBoundaryEmail')({
        errorMessage: error.message,
        errorName: error.name,
        errorLog: error.message.split(' at')[0],
        errorStack: errorInfo.componentStack,
        errorLocation: window.location.href,
        errorTime: new Date().toLocaleString(),
        user: Auth.currentUser?.email || 'No User Data found.',
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={this.errorStyle}>
          <Typography variant='h3'>Our Apologies!</Typography>
          <NotInterested style={{fontSize: '15em', fill: 'firebrick', padding: '16px 0px'}} />
          <Typography variant='h5'>We encountered an unexpected error.</Typography>
          <Typography variant='h5'>Please go back and reload the page.</Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]),
};
export default ErrorBoundary;
