// Auth Redux Types
export const SET_CURRENT_USER = 'authReduxTypes/SET_CURRENT_USER';
export const UNSET_CURRENT_USER = 'authReduxTypes/UNSET_CURRENT_USER';
export const SET_PORTAL_USERS = 'authReduxTypes/SET_PORTAL_USERS';

// Firestore Redux Types
export const SET_AFFILIATE_SITES = 'firestoreReduxTypes/SET_AFFILIATES';
export const SET_BATTERY_RETURNS = 'firestoreReduxTypes/SET_BATTERY_RETURNS';
export const SET_BATTERY_TYPES = 'firestoreReduxTypes/SET_BATTERY_TYPES';
export const SET_RECEIVING_SITES = 'firestoreReduxTypes/SET_RECEIVING_SITES';
export const UNSET_COLLECTIONS = 'firestoreReduxTypes/UNSET_COLLECTIONS';
