import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Typography} from '@material-ui/core';
import {Warning, LocalShipping} from '@material-ui/icons';


const ViewDetailsHeader = ({selectedReturn}) => {
  const {currentUser} = useSelector(state => state.auth);
  const isBatteryDamaged = useMemo(() => {
    const powerUser = ['Admin', 'KBI'].includes(currentUser.group);
    const noHazards = selectedReturn.Battery.Hazards.includes('None of the above.');
    if (noHazards) return false;
    else if (powerUser) return true;
  }, [currentUser.group, selectedReturn.Battery.Hazards]);
  const iconProps = useMemo(() => ({
    style: {
      fontSize: '10em',
      width: '100%',
    },
    color: isBatteryDamaged ? 'secondary' : 'primary',
  }), [isBatteryDamaged]);
  const mainTextProps = useMemo(() => ({
    gutterBottom: isBatteryDamaged ? false : true,
    style: {
      color: isBatteryDamaged ? 'red' : 'black',
      textAlign: 'center',
      width: '100%',
    },
    variant: 'h6',
  }), [isBatteryDamaged]);

  if (isBatteryDamaged) {
    return (
      <Fragment>
        <Warning {...iconProps} />
        <Typography {...mainTextProps}>Damaged Battery Return Details</Typography>
        <Typography variant='caption' gutterBottom style={{color: 'darkred', width: '100%', textAlign: 'center'}}>
          {selectedReturn.Battery.DamagedDesc}
        </Typography>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <LocalShipping {...iconProps} />
      <Typography {...mainTextProps}>Battery Return Details</Typography>
    </Fragment>
  );
};

ViewDetailsHeader.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewDetailsHeader;
