import React, {Fragment, useState, useCallback} from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Grid} from '@material-ui/core';
import {AsyncLoader} from 'components';
import {MainView, WarrantyTable, UserLogsTable, BillingSummaryTable} from './Reports/';

const AdminReports = () => {
  const [selectedReport, setReport] = useState({type: '', dateStart: '', dateEnd: ''});
  const {currentUser} = useSelector(state => state.auth);

  const goBack = useCallback(() => setReport({type: '', dateStart: '', dateEnd: ''}), []);
  if (!currentUser) return <AsyncLoader />;
  if (!['Admin', 'KBI'].includes(currentUser.group)) return <Redirect to={'/portal/battery-returns'} />;
  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {!selectedReport.type && <MainView setReport={setReport} />}
          {selectedReport.type === 'warranty-report' && <WarrantyTable selectedReport={selectedReport} goBack={goBack} />}
          {selectedReport.type === 'user-logs' && <UserLogsTable selectedReport={selectedReport} goBack={goBack} />}
          {selectedReport.type === 'billing-summary' && <BillingSummaryTable selectedReport={selectedReport} goBack={goBack} />}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AdminReports;
