import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import sortBy from 'lodash.sortby';
import {MenuItem, Grid, DialogContentText} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
const {AutoComplete, DateField, TextField, SelectField} = Formik;

const EditPickupSchedule = ({formik}) => {
  const {receivingSites} = useSelector(state => state.firestore);
  const receivingSiteProps = useMemo(() => ({
    label: 'Receiving Site',
    name: 'ReceivingSiteName',
    options: sortBy(receivingSites.list, 'Name') || [],
    optionKey: 'Name',
    required: true,
    onChange: ({field, form}) => {
      form.setFieldValue('ReceivingSiteRef', field.value.ReceivingSiteId);
      form.setFieldValue('ReceivingSiteCountry', field.value.Country);
      form.setFieldValue('ReceivingSiteAddress1', field.value.Address1);
      form.setFieldValue('ReceivingSiteAddress2', field.value.Address2);
      form.setFieldValue('ReceivingSiteCity', field.value.City);
      form.setFieldValue('ReceivingStateProvince', field.value.StateProvince);
      form.setFieldValue('ReceivingSitePostalCode', field.value.PostalCode);
    },
  }), [receivingSites.list]);
  return (
    <>
      <DialogContentText>Shipment Details</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SelectField name='Carrier'>
            <MenuItem value='FedEx'>FedEx</MenuItem>
            <MenuItem value='Old Dominion'>Old Dominion</MenuItem>
            <MenuItem value='Veolia'>Veolia</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField name='PickupDate' label='Pickup Date' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ShippingPaper' label='Shipping Paper No.' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ShippingPO' label='Shipping PO No.' />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoComplete {...receivingSiteProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ReceivingSiteCountry' label='Country' disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ReceivingSiteAddress1' label='Address 1' disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ReceivingSiteAddress2' label='Address 2' disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField name='ReceivingSiteCity' label='City' disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField name='ReceivingStateProvince' label={formik.values.ReceivingSiteCountry === 'Canada' ? 'Province' : 'State'} disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField name='ReceivingSitePostalCode' label='Postal Code' disabled />
        </Grid>
      </Grid>
    </>
  );
};

EditPickupSchedule.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default EditPickupSchedule;
