import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

const prodConfig = {
  apiKey: 'AIzaSyDosHMTrfIg-nkvt9QZ7PGHN2-HKfB_bSc',
  authDomain: 'harley-portal.firebaseapp.com',
  databaseURL: 'https://harley-portal.firebaseio.com',
  projectId: 'harley-portal',
  storageBucket: 'harley-portal.appspot.com',
  messagingSenderId: '743399782951',
  appId: '1:743399782951:web:9044f2c775f21bc77fac02',
};
const devConfig = {
  apiKey: 'AIzaSyDrOpj2_m2RCo9PklPVyCMMMI5kfQbAU8s',
  authDomain: 'harley-portal-dev.firebaseapp.com',
  databaseURL: 'https://harley-portal-dev.firebaseio.com',
  projectId: 'harley-portal-dev',
  storageBucket: 'harley-portal-dev.appspot.com',
  messagingSenderId: '976531954472',
  appId: '1:976531954472:web:c596bfcc5f348925ea4e1d',
  measurementId: 'G-4KXDNKE1SD',
};

firebase.firestore.setLogLevel('error');
const Fire = process.env.REACT_APP_ENV === 'production' ? firebase.initializeApp(prodConfig) : firebase.initializeApp(devConfig);

export const Firestore = Fire.firestore();
export const FieldValue = firebase.firestore.FieldValue;
export const Auth = Fire.auth();
export const Functions = Fire.functions();
export const Storage = Fire.storage();
