import React, {Fragment, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Paper, Typography} from '@material-ui/core';
import {AddBox, Edit, More} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {ReceivingSiteModal} from './index';

const ReceivingSiteTable = (props) => {
  const [modal, setModal] = useState({type: null, row: null});
  const {receivingSites} = useSelector(state => state.firestore);

  const btnMore = useMemo(() => ({
    color: 'primary',
    component: Link,
    style: {margin: '16px 0px'},
    startIcon: <More />,
    to: '/portal/admin-settings/',
    variant: 'outlined',
  }), []);
  const btnAddReceivingSite = useMemo(() => ({
    color: 'primary',
    startIcon: <AddBox />,
    style: {marginLeft: '16px'},
    variant: 'outlined',
    onClick: () => setModal({type: 'addReceivingSite'}),
  }), []);
  const tableProps = useMemo(() => ({
    actionsPerRow: [
        {icon: Edit, tooltip: 'Edit Receiving Site', onClick: ({event, rowData, rowIndex}) => setModal({type: 'editReceivingSite', row: rowData})}, // eslint-disable-line
    ],
    columns: [
      {Header: 'Active', accessor: 'Active', type: 'boolean'},
      {Header: 'Name', accessor: 'Name'},
      {Header: 'Address 1', accessor: 'Address1'},
      {Header: 'City', accessor: 'City'},
      {Header: 'State/Province', accessor: 'StateProvince'},
      {Header: 'Postal Code', accessor: 'PostalCode'},
      {Header: 'Country', accessor: 'Country'},
    ],
    data: receivingSites.list ? receivingSites.list : [],
    isLoading: !receivingSites.list,
    disableFilters: true,
    sortInitial: [{id: 'Name'}],
  }), [receivingSites]);

  return (
    <Fragment>
      <Button {...btnMore}>More Settings</Button>
      <Button {...btnAddReceivingSite}>Add Receiving Site</Button>
      <Paper>
        <Typography variant='h6' style={{padding: '8px 24px'}}>Receiving Sites</Typography>
        <Table {...tableProps} />
      </Paper>
      <ReceivingSiteModal {...modal} close={() => setModal({type: null, row: null})} />
    </Fragment>
  );
};

export default ReceivingSiteTable;
