import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Backdrop, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {array, object, string} from 'yup';
import moment from 'moment';
import {firestoreUtility, julianDateRegex, postalRegex, phoneRegex} from 'utilities';
import {DialogTitle, StagePickup, StageContact, StageBattery, StageHazards, StageHazmat, StageSuccess} from './ModalNewReturn/';
import {Firestore, Functions} from 'firebaseConfig';
import {Alert, Formik} from '@kbi/component-library';
const {FormikForm, SubmitButton, FormButton} = Formik;

const ModalNewReturn = ({open, close}) => {
  const classes = useStyles();
  const [stage, setStage] = useState('pickup');
  const [locationInfo, setLocationInfo] = useState(null);
  const {currentUser} = useSelector(state => state.auth);

  const getLocationInfo = useCallback(() => {
    const getDealerInfo = async () => {
      const userContactInfo = {
        ContactName: currentUser?.name ? currentUser.name : '',
        ContactEmail: currentUser?.email ? currentUser.email : '',
      };
      try {
        const {data} = await Functions.httpsCallable('harleyGetDlrAddress')(currentUser.dealerNo);
        // dealerNo 2198 references generic corporate office. Therefore no address should be populated.
        setLocationInfo({
          ...userContactInfo,
          LocationFound: true,
          DealerNo: currentUser.dealerNo,
          DealerName: currentUser.dealerNo !== '2198' ? data.DealerName : '',
          PickupAddress1: currentUser.dealerNo !== '2198' ? data.PickupAddress1 : '',
          PickupAddress2: currentUser.dealerNo !== '2198' ? data.PickupAddress2 : '',
          PickupCity: currentUser.dealerNo !== '2198' ? data.PickupCity : '',
          PickupStateProvince: currentUser.dealerNo !== '2198' ? data.PickupStateProvince : '',
          PickupPostalCode: currentUser.dealerNo !== '2198' ? data.PickupPostalCode : '',
          PickupCountry: currentUser.dealerNo !== '2198' ? data.PickupCountry : '',
        });
      }
      catch (error) {
        setLocationInfo({...userContactInfo, LocationFound: true});
      }
    };
    const getAffiliateInfo = async () => {
      const userContactInfo = {
        ContactName: currentUser?.name ? currentUser.name : '',
        ContactEmail: currentUser?.email ? currentUser.email : '',
      };
      try {
        const doc = await Firestore.collection('AffiliateSites').doc(currentUser.affiliateId).get();
        const {Name, Address1, Address2, City, StateProvince, PostalCode, Country} = doc.data();
        setLocationInfo({
          ...userContactInfo,
          LocationFound: true,
          AffiliateId: currentUser.affiliateId,
          AffiliateName: Name,
          PickupAddress1: Address1,
          PickupAddress2: Address2,
          PickupCity: City,
          PickupStateProvince: StateProvince,
          PickupPostalCode: PostalCode,
          PickupCountry: Country,
        });
      }
      catch (error) {
        setLocationInfo({...userContactInfo, LocationFound: true});
      }
    };
    if (currentUser?.group === 'H-D Net') getDealerInfo();
    if (currentUser?.group === 'Affiliate') getAffiliateInfo();
  }, [currentUser]);
  useEffect(() => getLocationInfo(), [getLocationInfo]);

  const handleBack = useCallback(() => {
    if (stage === 'contact') setStage('pickup');
    if (stage === 'battery') setStage('contact');
    if (stage === 'hazards') setStage('battery');
    if (stage === 'hazmat') setStage('hazards');
  }, [stage]);
  const handleClose = useCallback(() => {
    setStage('pickup');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {
      LocationFound: locationInfo?.LocationFound ? locationInfo.LocationFound : false,
      LocationType: ['Affiliate', 'H-D Net'].includes(currentUser.group) ? currentUser.group : 'H-D Net',
      DealerNo: locationInfo && currentUser.group === 'H-D Net' ? locationInfo.DealerNo : '',
      AffiliateId: locationInfo && currentUser.group === 'Affiliate' ? locationInfo.AffiliateId : '',
      DealerName: locationInfo && currentUser.group === 'H-D Net' ? locationInfo.DealerName : '',
      AffiliateName: locationInfo && currentUser.group === 'Affiliate' ? locationInfo.AffiliateName : '',
      PickupAddress1: locationInfo?.PickupAddress1 ? locationInfo.PickupAddress1 : '',
      PickupAddress2: locationInfo?.PickupAddress2 ? locationInfo.PickupAddress2 : '',
      PickupCity: locationInfo?.PickupCity ? locationInfo.PickupCity : '',
      PickupStateProvince: locationInfo?.PickupStateProvince ? locationInfo.PickupStateProvince : '',
      PickupPostalCode: locationInfo?.PickupPostalCode ? locationInfo.PickupPostalCode : '',
      PickupCountry: locationInfo?.PickupCountry ? locationInfo.PickupCountry : '',
      ContactName: locationInfo?.ContactName ? locationInfo.ContactName : '',
      ContactEmail: locationInfo?.ContactEmail ? locationInfo.ContactEmail : '',
      ContactPhone: '',
      ContactFax: '',
      BatteryPartNo: '',
      BatteryMfgSerialNo: '',
      BatteryJulianDate: '',
      BatteryName: '',
      BatteryRate: '',
      BatteryShippedSerialNo: '',
      BatteryVinAvailable: 'Yes',
      BatteryVinNumber: '',
      BatteryWarranty: '',
      BatteryWarrantyAuth: '',
      BatteryHazards: [],
      HazmatCertified: '',
    },
    onSubmit: (values, actions) => {
      const triggerDealerNoError = () => {
        actions.setFieldError('DealerNo', 'Must enter Dealer No. to continue.');
        actions.setSubmitting(false);
      };
      const gotoStage = (stage) => {
        setStage(stage);
        actions.setTouched({});
        actions.setSubmitting(false);
        actions.setStatus({text: '', in: false, severity: 'warning'});
      };
      const submitData = async () => {
        try {
          const docFields = {
            Accounting: {
              DateClosed: null,
              Status: 'Open',
              Total: null,
              Lines: [
                // No charge for recycling if battery is being sent to Samsung for Warranty return
                {Amount: values.BatteryWarranty === 'Yes' ? 0 : values.BatteryRate, Description: 'Battery Recycling Fee'},
                {Amount: 55, Description: 'Tracking Admin Fee'},
                {Amount: 0, Description: 'Transportation Fee'},
              ],
            },
            Battery: {
              Hazards: values.BatteryHazards,
              SerialNoReceived: null,
              SerialNoShipped: values.BatteryShippedSerialNo.trim(),
              JulianDate: values.BatteryJulianDate.trim(),
              MfgSerialNo: values.BatteryMfgSerialNo.trim(),
              Name: values.BatteryName,
              PartNo: values.BatteryPartNo,
              VinAvailable: values.BatteryVinAvailable,
              Warranty: values.BatteryWarranty,
              WarrantyAuth: values.BatteryWarrantyAuth.trim(),
            },
            BatteryVin: values.BatteryVinNumber.trim(),
            Contact: {
              Name: values.ContactName.trim(),
              Email: values.ContactEmail.trim(),
              Phone: values.ContactPhone.trim(),
              Fax: values.ContactFax.trim(),
            },
            Date: {
              Received: null,
              Reminder: moment().add(1, 'days').toDate(),
              Requested: new Date(),
            },
            Hazmat: {
              Certified: values.HazmatCertified === 'Hazmat' ? true : false,
              CertifiedBy: {
                Name: currentUser.name,
                Email: currentUser.email ? currentUser.email : '',
                Group: currentUser.group,
                UserID: currentUser.uid,
                Timestamp: new Date(),
              },
            },
            LocationType: values.LocationType,
            Notes: [{
              Note: `Battery return requested on ${moment().format('MM/DD/YYYY')}.`,
              CreatedOn: new Date(),
              CreatedBy: 'System',
            }],
            PickupLocation: {
              Address1: values.PickupAddress1,
              Address2: values.PickupAddress2,
              City: values.PickupCity,
              StateProvince: values.PickupStateProvince,
              PostalCode: values.PickupPostalCode,
              Country: values.PickupCountry,
            },
            ReturnNo: 'Pending...',
            ShipmentDetails: null,
            Status: 'Request Received',
            System: {
              CreatedBy: currentUser.name,
              CreatedUid: currentUser.uid,
              CreatedOn: new Date(),
              ModifiedBy: currentUser.name,
              ModifiedUid: currentUser.uid,
              ModifiedOn: new Date(),
            },
          };
          if (values.LocationType === 'H-D Net') {
            docFields.DealerNo = values.DealerNo;
            docFields.DealerName = values.DealerName;
          }
          if (values.LocationType === 'Affiliate') {
            docFields.AffiliateId = values.AffiliateId;
            docFields.AffiliateName = values.AffiliateName;
          }
          const addDocument = {
            collection: 'BatteryReturns',
            docFields,
            writeType: 'add',
          };
          await firestoreUtility(addDocument);
          setStage('success');
        }
        catch (error) {
          actions.setStatus({in: true, severity: 'error', text: error.message});
          actions.setSubmitting(false);
        }
      };
      if (stage === 'pickup' && !values.LocationFound && values.LocationType === 'H-D Net') triggerDealerNoError();
      else if (stage === 'pickup') gotoStage('contact');
      else if (stage === 'contact') gotoStage('battery');
      else if (stage === 'battery') gotoStage('hazards');
      else if (stage === 'hazards') gotoStage('hazmat');
      if (stage === 'hazmat') submitData();
    },
    validationSchema: () => {
      const pickupValidation = object().shape({
        DealerNo: string().label('Dealer No.').when('LocationType', {
          is: 'H-D Net',
          then: string().required('Must enter Dealer No. to continue.'),
          otherwise: string().notRequired(),
        }),
        AffiliateId: string().label('Dealer No.').nullable().when('LocationType', {
          is: 'Affiliate',
          then: string().required('Must enter Affiliate Id to continue.'),
          otherwise: string().notRequired(),
        }),
        DealerName: string().label('Dealer Name').when('LocationType', {
          is: 'H-D Net',
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        AffiliateName: string().label('Affiliate Name').when('LocationType', {
          is: 'Affiliate',
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        PickupAddress1: string().label('Pickup Address 1').when('LocationFound', {
          is: val => val,
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        PickupAddress2: string().label('Pickup Address 2').notRequired(),
        PickupCity: string().label('City').when('LocationFound', {
          is: val => val,
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        PickupStateProvince: string().label('State').when('LocationFound', {
          is: val => val,
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        PickupPostalCode: string().label('Postal Code').when('LocationFound', {
          is: val => val,
          then: string().required().matches(postalRegex, 'Postal Code is improperly formatted. (e.g. #####)'),
          otherwise: string().notRequired(),
        }),
        PickupCountry: string().label('Country').when('LocationFound', {
          is: val => val,
          then: string().required(),
          otherwise: string().notRequired(),
        }),
      });
      const contactValidation = object().shape({
        ContactName: string().label('Name').required(),
        ContactEmail: string().label('Email').required().email(),
        ContactPhone: string().label('Phone').required().matches(phoneRegex, 'Not properly formatted (e.g. 555-555-5555)'),
        ContactFax: string().label('Fax').notRequired().matches(phoneRegex, 'Not properly formatted (e.g. 555-555-5555)'),
      });
      const batteryValidation = object().shape({
        BatteryPartNo: string().label('Part No.').required(),
        BatteryMfgSerialNo: string().label('Serial No.').required(),
        BatteryJulianDate: string().label('Julian Date').required().matches(julianDateRegex, 'Not properly formatted (e.g. YYDDD)'),
        BatteryVinAvailable: string().label('VIN Available').when('BatteryWarranty', {
          is: 'Yes',
          then: string().oneOf(['Yes'], 'VIN required for warranty returns'),
          otherwise: string().notRequired(),
        }),
        BatteryVinNumber: string().label('VIN Number').when('BatteryVinAvailable', {
          is: 'Yes',
          then: string().required().min(17),
          otherwise: string().notRequired(),
        }),
        BatteryWarranty: string().label('Warranty Return').required(),
        BatteryWarrantyAuth: string().label('Warranty Auth No.').when('BatteryWarranty', {
          is: 'Yes',
          then: string().required().min(6, 'Auth No. must be at least 6 characters'),
          otherwise: string().notRequired(),
        }),
      });
      const hazardsValidation = object().shape({
        BatteryHazards: array().required(`Battery Hazards is a required field. Select 'None of the above' if no hazards identified.`),
      });
      const hazmatValidation = object().shape({
        HazmatCertified: string().label('Hazmat Training Certification').required(),
      });
      if (stage === 'pickup') return pickupValidation;
      if (stage === 'contact') return contactValidation;
      if (stage === 'battery') return batteryValidation;
      if (stage === 'hazards') return hazardsValidation;
      if (stage === 'hazmat') return hazmatValidation;
    },
  }), [currentUser, locationInfo, stage]);

  if (currentUser.group === 'H-D Net' && !locationInfo) return <Backdrop className={classes.backdrop} open={open}><CircularProgress color='inherit' /></Backdrop>;
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({errors, setStatus, status, values}) => (
          <Fragment>
            <DialogTitle stage={stage} />
            <DialogContent>
              <StagePickup stage={stage} />
              <StageContact stage={stage} />
              <StageBattery stage={stage} />
              <StageHazards stage={stage} />
              <StageHazmat stage={stage} formValues={values} />
              <StageSuccess stage={stage} />
              <Alert text={status.text} in={status.in} severity={status.severity} onClose={() => setStatus({...status, in: false})} />
            </DialogContent>
            <Collapse in={stage === 'success'}>
              <DialogActions>
                <Button onClick={handleClose} variant='outlined'>Close</Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'}>
              <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <FormButton color='secondary' variant='text' onClick={handleClose}>Cancel</FormButton>
                </div>
                <div>
                  {stage !== 'pickup' && <FormButton color='primary' variant='text' onClick={handleBack}>Back</FormButton>}
                  <SubmitButton variant='text'>{stage === 'hazmat' ? 'Submit' : 'Next'}</SubmitButton>
                </div>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
ModalNewReturn.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
export default ModalNewReturn;
