import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import {Collapse, Formik} from '@kbi/component-library';
const {TextField} = Formik;

const StageContact = ({stage}) => {
  return (
    <Collapse in={stage === 'contact'}>
      <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Contact Information</Typography>
      <Typography variant='subtitle1' style={{marginBottom: '8px'}}>
        Enter details for on-site employee responsible for this battery pickup.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactName' label='Name' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactEmail' label='Email' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactPhone' label='Phone' required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name='ContactFax' label='Fax' />
        </Grid>
      </Grid>
    </Collapse>
  );
};

StageContact.propTypes = {
  stage: PropTypes.string.isRequired,
};
export default StageContact;
