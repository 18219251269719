import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Grid, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import moment from 'moment';
import {date, object, string} from 'yup';
import {firestoreUtility} from 'utilities';
import {Alert, Collapse, Formik} from '@kbi/component-library';
const {DateField, FormButton, FormikForm, SubmitButton, TextField} = Formik;

const ModalReceiveBattery = ({close, open, selectedReturn}) => {
  const [alert, setAlert] = useState({text: '', in: false, severity: 'warning'});
  const [stage, setStage] = useState('receiveBattery');
  const handleClose = useCallback(() => {
    setStage('receiveBattery');
    close();
  }, [close]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'xs',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]); ;
  const formProps = useMemo(() => ({
    initialValues: {
      DateReceived: moment().format('YYYY-MM-DD'),
      SerialNoShipped: selectedReturn?.Battery?.SerialNoShipped || '',
      SerialNoReceived: '',
    },
    onSubmit: async (values, actions) => {
      try {
        const email = {
          collection: 'Emails',
          writeType: 'add',
          docFields: {
            to: selectedReturn.Contact.Email,
            bcc: ['Julie.Batchelor@harley-davidson.com', 'daniel@kbirecycling.com'],
            template: {
              data: {ReturnNo: selectedReturn.ReturnNo},
              name: 'BatteryReceived',
            },
          },
        };
        const updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: selectedReturn.BatteryReturnId,
          docFields: {
            'Date.Received': moment(values.DateReceived).toDate(),
            'Date.Reminder': moment().add(1, 'days').toDate(),
            'Battery.SerialNoReceived': values.SerialNoReceived,
            'Status': 'Battery Received',
          },
        };
        await Promise.all([firestoreUtility(updateDoc), firestoreUtility(email)]);
        setTimeout(() => actions.resetForm(), 250);
        setStage('success');
        setAlert({...alert, in: false});
      }
      catch (error) {
        setAlert({in: true, severity: 'error', text: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      DateReceived: date().label('Date Received').required().max(new Date(), 'Date Requested cannot be set in the future'),
      SerialNoReceived: string().label('Serial Number').required().min(3),
    }),
  }), [alert, selectedReturn]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => <Fragment>
          <DialogTitle>
            <Collapse in={stage !== 'success'}>Receive Battery</Collapse>
          </DialogTitle>
          <DialogContent>
            <Collapse in={stage === 'receiveBattery'}>
              <DialogContentText>Enter date received and serial number.</DialogContentText>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DateField name='DateReceived' label='Date Received' required />
                </Grid>
                <Grid item xs={12}>
                  <TextField name='SerialNoShipped' label='Serial Number Shipped' required disabled />
                </Grid>
                <Grid item xs={12}>
                  <TextField name='SerialNoReceived' label='Serial Number Received' required />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={stage === 'success'}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <CheckCircleOutline style={{fontSize: '10em'}} />
                <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
                    Battery was received into portal.
                </Typography>
              </div>
            </Collapse>
            <Alert text={alert.text} in={alert.in} severity={alert.severity} onClose={() => setAlert({...alert, in: false})} />
          </DialogContent>
          <Collapse in={stage === 'success'}>
            <DialogActions>
              <FormButton onClick={handleClose} color='primary' variant='text'>Close</FormButton>
            </DialogActions>
          </Collapse>
          <Collapse in={stage !== 'success'}>
            <DialogActions>
              <FormButton onClick={handleClose} color='secondary' variant='text'>Cancel</FormButton>
              <SubmitButton color='primary' variant='text' />
            </DialogActions>
          </Collapse>
        </Fragment>}
      </FormikForm>
    </Dialog>
  );
};

ModalReceiveBattery.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedReturn: PropTypes.object.isRequired,
};
export default ModalReceiveBattery;
