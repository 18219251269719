import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Typography, ListItemIcon, ListItemText, MenuItem, MenuList} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CloudDownload} from '@material-ui/icons';

const ViewFiles = ({selectedReturn}) => {
  const styles = useStyles();
  const fileCount = useMemo(() => {
    const files = selectedReturn?.Files || null;
    if (!files) return 0;
    else return Object.keys(files).length;
  }, [selectedReturn]);

  if (!selectedReturn.Files || fileCount === 0) return null;
  return (
    <MenuList className={styles.menuSide}>
      <Typography variant='subtitle1' className={styles.viewTitle}>
        Downloadable Documents
      </Typography>
      {selectedReturn?.Files?.['Shipping_Document'] && <MenuItem onClick={() => window.open(selectedReturn.Files['Shipping_Document'], '_blank')} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText primary='Shipping Document' />
      </MenuItem>}
      {selectedReturn?.Files?.['Hazmat_Marking'] && <MenuItem onClick={() => window.open(selectedReturn.Files['Hazmat_Marking'], '_blank')} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText primary='Hazmat Marking' />
      </MenuItem>}
      {selectedReturn?.Files?.['Shipping_Label'] && <MenuItem onClick={() => window.open(selectedReturn.Files['Shipping_Label'], '_blank')} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText primary='Shipping Label' />
      </MenuItem>}
      {selectedReturn?.Files?.['Battery_Return_Form'] && <MenuItem onClick={() => window.open(selectedReturn.Files['Battery_Return_Form'], '_blank')} style={{padding: '8px'}}>
        <ListItemIcon style={{paddingRight: '16px', minWidth: '16px'}}>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText primary='Battery Return Form' />
      </MenuItem>}
    </MenuList>
  );
};

const useStyles = makeStyles(theme => ({
  viewTitle: {
    marginLeft: '8px',
    textAlign: 'left',
    width: '100%',
  },
  menuSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
}));
ViewFiles.propTypes = {
  selectedReturn: PropTypes.object.isRequired,
};
export default ViewFiles;
